import React from "react";
import {
  // Customizable Area Start
  Box,
  Typography,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Badge,
  Drawer,
  Switch,
  Tooltip
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  bellicon_img,
  help_img_,
  lancerscapelogo,
  sms_image,
  switchbtn,
} from "./assets";
import "../assets/css/LancerDashboard.css";
import SearchIcon from "@material-ui/icons/Search";
import HeaderController, { Props } from "./HeaderController.web";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import Notificationbox from "../../notificationsettings/src/Notificationbox.web";
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

export const LancerOnlineBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#44b700",
      color: "#44b700",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
  })
)(Badge);

export const LancerOfflineBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: "#ff9808",
      color: "#ff9808",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
  })
)(Badge);
// Customizable Area End


export class FreelancerHeader extends HeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.currentPage = "Freelancer";
    // Customizable Area End
  }
  // Customizable Area Start
  addLines = (isSwitchable: boolean, route: string) => {
    if (isSwitchable && route !== "dashboard") {
      return (
        <>
          <br />
          <br />
          <br />
        </>
      )
    }
  }

  addLine = (route: string) => {
    if (route !== "dashboard") {
      return (
        <br />
      )
    }

  }
  getFavouritesStyle = () => {
    return ({ color: this.props.route == "favourites" ? "#EC952D" : ""  })
  }


  renderProfilePic = (loggedInUser: any, shortCode: string) => {
    return <Avatar src={loggedInUser?.attributes?.photo[0]?.url != null && loggedInUser?.attributes?.photo[0]?.url}>{shortCode?.toUpperCase()}</Avatar>
  }

  lancerrenderBadge = (availability: any, loggedInUser: any, shortCode: any) => {
    const BadgeComponent = availability ? LancerOnlineBadge : LancerOfflineBadge;

    return (
      <BadgeComponent
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
      >
        {/* <Avatar>{shortCode}</Avatar> */}
        {this.renderProfilePic(loggedInUser, shortCode)}
      </BadgeComponent>
    );
  }

  LancerSwitch = withStyles((theme: Theme) =>
    createStyles({


      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: theme.palette.common.white,
          '& + $track': {
            opacity: 1,
            backgroundColor: 'rgb(236, 149, 45)', // Change this line to set the background color
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          border: '6px solid #fff',
          color: 'rgb(236, 149, 45)',
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        backgroundColor: '#808080',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      focusVisible: {},
      checked: {},
    })
  )(Switch);

  renderMessageCountBubble = () => {
    return this.state.unreadNotifications.length > 0 &&
      <span className="lancernotification-bar">
        <span>{this.state.unreadNotifications.length}</span>
      </span>
  }

  renderNotificationCountBubble = () => {
    return this.state.notificationcount > 0 &&
      <span className="lancernotification-bar">
        <span>{this.state.notificationcount}</span>
      </span>
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { mobileOpen, profilePopup, notificationPopup, currentUser: loggedInUser } = this.state;
    const openProfileModel = Boolean(profilePopup);
    const openNotificationPopup = Boolean(notificationPopup);
    const isSwitchable = loggedInUser?.attributes?.role_name?.toLowerCase().includes("both");
    const currentUserEmail = loggedInUser?.attributes?.account_detail?.data?.attributes?.email;
    let maskedEmail = "";
    if (currentUserEmail) {
      const [username, domain] = currentUserEmail.split('@');
      maskedEmail = `${username.slice(0, 5)}....@${domain}`;
    }
    const lastName = loggedInUser?.attributes?.last_name;
    const firstName = loggedInUser?.attributes?.first_name;
    const loggedInuser = `${firstName} ${lastName}`
    let shortCode = "";
    if (firstName && lastName) {
      shortCode =
        firstName.trim().substring(0, 1) + lastName.trim().substring(0, 1);
    } else {
      shortCode = currentUserEmail?.trim().substring(0, 2) as string;
    }




    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        <style>
          {`
            .MuiTooltip-tooltip {
              font-size: 11px;
              color: #fff;
              font-weight: 400;
              background-color: #222222;
              padding: 8px;
              border: none;
              border-radius: 5px;
              max-width:250px;
            }
          `}
        </style>
        <AppBar
          // position="fixed"
          style={{ background: "white", color: "black" }}
        >
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box style={{ display: "flex", alignItems: "center", gap: "20px" }}
              data-test-id="togglemobile">
              <Box
                data-test-id="menu"
                style={{ display: 'flex' }}>
                <IconButton
                  onClick={this.handleDrawerOpen}
                  className={classes.menu_icon}

                >
                  <MenuIcon htmlColor="black" />
                </IconButton>
                <img
                  data-test-id="logo1"
                  style={{ width: "100%", height: "100%", objectFit: "cover", cursor: "pointer" }}
                  className={classes.hide_item_on__above_mobile}
                  src={lancerscapelogo}
                  alt="log_image"
                  onClick={this.navigateToHomePage}
                />
              </Box>
              <Box
                data-test-id="hidden_item_on_mobile"
                className={classes.hidden_item_on_mobile}
                style={{ width: "120px" }}
              >
                <img
                  data-test-id="logo2"
                  style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
                  src={lancerscapelogo}
                  alt="log_image"
                  onClick={this.navigateToHomePage}
                />
              </Box>
              <Box className={classes.nav__hidden__items}>
              
              <ul className={`${classes.nav__hidden__items_list} navigationed`}>
                <li
                  data-test-id="dashboardnav"
                  className={this.props.route == "dashboard" ? "active" : ""}
                >
                  <Link to="/FreelancerDashboard">
                    Dashboard
                  </Link>
                </li>
                <li
                  data-test-id="projectnav"
                  className={this.props.route == "projects" ? "active" : ""}
                >
                  <Link to="/FreelancerProjects/browse-all">
                    Projects
                  </Link>
                </li>
                <li
                  data-test-id="favouritenav"
                  className={this.props.route == "favourites" ? "active" : ""}
                >
                  <Link to="/favorites-project">
                    Favourites
                  </Link>
                </li>
              </ul>
            </Box>
            </Box>
            <Box
              data-test-id="rightSideCotent"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box
                data-test-id="hiddenContent"
                className={classes.hidden_items_on_mobile_flex}
                style={{ alignItems: "center", gap: "5px" }}
              >
                {isSwitchable &&
                  <Box
                    data-test-id="sponser"
                    onClick={this.sendSwitchAccountMessage}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "80px",
                      margin: "10px",
                      width: "80px",
                      border: "1px solid #EC952D",
                      borderRadius: "15px",
                      cursor: "pointer",
                    }}

                  >
                    <img className={classes.sponsorLogo} src={switchbtn} />
                    <Typography
                      style={{
                        fontSize: "12px",
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      Switch to Sponsor
                    </Typography>
                  </Box>}
                  <Box className="lancernotification-count">

                <Box onClick={()=>this.unreadMessageNotifsAndRedirect()} data-test-id="check-message-notif-btn">
                  <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} arrow placement="top-start" title="Messages">
                  <IconButton style={{ padding: '6px' }} >
                      <img src={sms_image}/>
                  </IconButton>
                  </Tooltip>
                  {this.renderMessageCountBubble()}
                </Box>
                  </Box>
                <Box className="lancernotification-count">
                <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} arrow placement="bottom" title="Notifications">
                  <IconButton
                    id="btnnon"
                    onClick={(e) => this.handleOpenNotificationPopup(e)}
                    style={{ padding: '6px' }}>
                    <img src={bellicon_img} />
                  </IconButton>
                </Tooltip>  
                  {this.renderNotificationCountBubble()}
                </Box>
                <Link to="/Contactus" data-test-id="contactUsImg">
                <Tooltip enterTouchDelay={0} leaveTouchDelay={3000} arrow placement="bottom" title="Contact Us" className="MuiTooltip">
                  <IconButton style={{ padding: '6px' }}>
                    <img src={help_img_}/>
                  </IconButton>
                </Tooltip>
                </Link>

              </Box>
              <Button
                onClick={this.handleProfilePopupOpen}
                aria-controls="menu"
                color="inherit"
                style={{
                  textTransform: "capitalize",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                data-test-id="profile"
              >

                {this.lancerrenderBadge(this.state.currentUser?.attributes?.availability, loggedInUser, shortCode)}

                {loggedInUser &&
                  <>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                      className="resAccountName"
                    >
                      <Typography style={{ fontWeight: "bold" }}>
                        {currentUserEmail?.slice(0, 10)}...                      </Typography>
                      <Typography>Freelancer</Typography>
                    </Box>
                    <ExpandMoreIcon />
                  </>}
              </Button>

              <Menu
                // id='menu'
                key="profile_popup"
                anchorEl={profilePopup}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openProfileModel}
                onClose={this.handleCloseProfilePopup}
                style={{
                  marginTop: "64px",
                }}
              >
                <Box style={{ display: "flex", margin: "5px 10px", alignItems: "center" }} id="lancer">
                  <Box>
                    {this.lancerrenderBadge(this.state.currentUser?.attributes?.availability, loggedInUser, shortCode)}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }} test-id="lancername">
                      {loggedInuser}
                    </Typography>
                    <p style={{ margin: "0px", fontWeight: "bold", color: "gray" }}>Freelancer</p>
                  </Box>
                </Box>
                <hr style={{ margin: '0px', padding: '0px' }} className="lancerhr"/>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <MenuItem style={{ marginRight: '30px' }} data-test-id="lanceravailibility">
                    Availability
                  </MenuItem>
                  <this.LancerSwitch style={{ background: "#rgb(236, 149, 45)" }} data-id="Switch" checked={this.state.currentUser?.attributes?.availability} name="Switch" onChange={() => {
                    this.handleavailability()
                  }} />
                </Box>
                <hr style={{ margin: '0px', padding: '0px' }} className="lancerhr" />
                <Link to={"/UserProfile"}
                  style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Box style={{ display: "flex", margin: "0px 10px", alignItems: "center" }}>

                    <AccountCircleOutlinedIcon />
                    <MenuItem style={{paddingRight: '30px' , width:"100%" }}>
                      My Profile
                    </MenuItem>
                  </Box>
                </Link>
                <Box style={{ display: "flex", margin: "0px 10px", alignItems: "center" }}>

                  <ExitToAppOutlinedIcon style={{ transform: 'rotate(180deg)' , cursor:"pointer" }}  onClick={this.sendLogoutMessage}/>
                  <MenuItem
                    onClick={this.sendLogoutMessage}
                    style={{paddingRight: '30px' , width:"100%" }}
                  >
                    Logout
                  </MenuItem>
                </Box>
              </Menu>
              <Menu
                data-test-id="notification"
                anchorEl={notificationPopup}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openNotificationPopup}
                onClose={this.handleCloseNotificationPopup}
                style={{
                  top: "15px",
                }}
                className={`${isSwitchable ? "notification-main-menu-switch" :""} notification-main-menu`}
              >
                <Notificationbox navigation={this.props.navigation} id={""}></Notificationbox>
              </Menu>
            </Box>

          </Toolbar>
        </AppBar>
        <Drawer
          data-test-id="drawers"
          id="leftSideMenu"
          anchor={"left"}
          open={mobileOpen}
          onClose={this.handleDrawerClose}
        >
          <Box
            data-test-id="drawerContainer"
            id="menuContainer"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              justifyContent: "center",
              alignItems: 'center',
              width: "300px",
              marginTop: "15px",
            }}

          >
            <Box
              data-test-id="logoImage"
              style={{ width: "120px", margin: "0 auto" }}>
              <img
                data-test-id="logo3"
                style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
                src={lancerscapelogo}
                alt="log_image"
                onClick={() => { this.props.navigation.navigate('FreelancerDashboard') }}
              />
            </Box>
            <Link to="/FreelancerDashboard">
              <Button
                className={`${classes.nav__item}`}

                data-test-id='dashboard'
                style={{ color: this.props.route == "dashboard" ? "#EC952D" : "" }}
              >
                Dashboard
              </Button>
            </Link>
            <Link to="/FreelancerProjects/browse-all">
              <Button className={`${classes.nav__item}`}
                data-test-id='projects'
                style={{ color: this.props.route == "projects" ? "#EC952D" : "" }}>
                Projects
              </Button>
            </Link>
            <Link to="">
              <Button className={`${classes.nav__item}`}
                data-test-id='reports'
                style={{ color: this.props.route == "reports" ? "#EC952D" : "" }}>
                Reports
              </Button>
            </Link>
            <Link to="/favorites-project">
              <Button className={`${classes.nav__item} `}
                data-test-id='favourites'
                style={this.getFavouritesStyle()}>
                Favourites
              </Button>
            </Link>
            {/* <Divider /> */}
            <Link to="/Chat">
              <Button className={`${classes.nav__item}`}
                data-test-id='message'
                style={{ color: this.props.route == "chat" ? "#EC952D" : "" }}>
                Message
              </Button>
            </Link>
            <Link to="/Notificationlancer">
              <Button className={`${classes.nav__item}`}
                data-test-id='notifications'
              >
                Notifications <span className="lancernotification-bar"><span>{this.state.notificationcount}</span></span>
              </Button>
            </Link>
            <Link to="/Contactus">
              <Button className={`${classes.nav__item}`}
                data-test-id='contactus'
              >
                Contact Us
              </Button>
            </Link>
            {isSwitchable &&
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80px",
                  margin: "0 auto",
                  width: "80px",
                  border: "2px solid #EC952D",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
                onClick={this.sendSwitchAccountMessage}
              >
                <img className={classes.sponsorLogo} src={switchbtn} />
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Switch to Sponsor
                </Typography>
              </Box>}
          </Box>
        </Drawer>
        <br />
        <br />
        <br />
        {this.addLine(this.props.route)}
        {this.addLines(isSwitchable as boolean, this.props.route)}
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const classNames: any = (theme: Theme) =>
  createStyles({
    search_box: {
      width: "100%",
      margin: "0 auto",
      borderRadius: "24px",
      border: "1px solid #e4e6e8",
      padding: "10px",
      maxWidth: "500px",
    },
    active_nav: {
      color: "rgb(236, 149, 45)",
      borderBottom: "5px solid rgb(236, 149, 45)",
      fontWeight: "bolder",
      textTransform: 'none',
    },
    menu_icon: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    nav__hidden__items_list: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
      },
    },
    notifications__container: {
      padding: "30px",
    },
    nav__item: {
      fontWeight: "bolder",
    },
    hidden_items_on_mobile_flex: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    hidden_item_on_mobile: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },
    hide_item_on__above_mobile: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    sponsorLogo: {
      height: "20px",
      marginTop: "8.5px",

    },
    profile_menuItems: {
      color: 'inherit',
      textDecoration: 'none',
      marginLeft: "5px",
      marginRight: '10px',
    }

  })

const FreelancerHeaderStyles = withStyles(classNames, { withTheme: true })(FreelancerHeader);
export default FreelancerHeaderStyles

// Customizable Area End