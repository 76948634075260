
import { Select,FormControl,FormHelperText,createStyles,
    makeStyles,Theme,Typography } from "@material-ui/core";
import React, { useState } from "react";
interface Props{
    label:string;
    onChange:any;
    data:any[];
    renderItem:(item:any,props?:any)=>void;
    value:string;
    name?:string;
    id?:string;
    tabIndex?:number;
    error?:string;
    placeholder:string;
    disabled?:boolean;
    labelStyle?:any;
    rootStyle?:any;
    selectStyle?:any;
    marginBottom?: string;
    labelSuffix?:React.ReactNode
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlStyle: {
      },
      labelStyle: {
        color: "#8083A3",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 700,
        marginBottom:"10px"

      },
      selectItemValuestyle: {
        color: "#8083A3",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 400,
        borderBottom:"1px solid #e4e6e8",
        "&:hover": {
            borderBottom:"1px solid #e4e6e8",
          },
          "&::before": {
            borderBottom:"0px !important",
          },
          "&::after": {
            borderBottom:"0px !important",
          },
          "&$selected": {
            borderBottom:"0px !important",
          },
          "&.MuiSelect-select":{
            paddingBottom:"2px"
          }
      },
      placeholderStyle: {
        color: "#8083A3",
        fontSize: "14px",
        fontFamily: "Inter",
        lineHeight:"1.3"
        //fontWeight: 700,
      },
  })
);
const CustomSelect:React.FC<Props> = ({
    value,
    name,
    label,
    onChange,
    id,
    tabIndex,
    data,
    renderItem,
    error,
    placeholder,
    disabled=false,
    labelStyle={},
    rootStyle={},
    selectStyle={},
    marginBottom,
    labelSuffix=null
}) => {
    const webStyle=useStyles();
    const [spaceBelow, setSpacBelow] = useState<boolean>(false)
    const handleOpenSelect = () => {
      const selectRect = document.getElementById(id || "")?.getBoundingClientRect()
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      const spaceBelow = windowHeight - (selectRect?.bottom || 0)
      const dataLength = data?.length || 1
      const maxItems = 5
      const lengthToUse = (dataLength >= maxItems ? maxItems : dataLength)
      setSpacBelow(spaceBelow < ((lengthToUse/2) *100))
    }
    return ( 
        <div style={{marginBottom,width:"100%",...rootStyle}}>
                <FormControl 
                    fullWidth 
                    
                    className={webStyle.formControlStyle}
                    >
                      <FormHelperText 
                          style={labelStyle}
                          className={webStyle.labelStyle}
                          component="p">
                        {label} 
                        {
                          labelSuffix
                        }
                      </FormHelperText>
                      <Select
                        style={selectStyle}
                        disabled={disabled}
                        value={value}
                        id={id}
                        onChange={onChange}
                        name={name}
                        MenuProps={{
                          PaperProps: {
                            style:{
                              maxHeight:"250px",
                            }
                          },
                          anchorOrigin: {
                            vertical: spaceBelow ? "top" : "bottom",
                            horizontal: "center"
                          },
                          transformOrigin: {
                            vertical: spaceBelow ? "bottom" : "top",
                            horizontal: "center"
                          },
                          getContentAnchorEl:null
                        }}
                        className={webStyle.selectItemValuestyle}
                        renderValue={!value ? () => (
                                  <Typography
                                    className={
                                      webStyle.placeholderStyle
                                    }
                                  >
                                    {placeholder}
                                  </Typography>
                                ):undefined
                          }
                        inputProps={{
                          tabIndex:tabIndex,
                          style:{
                            minHeight:value?"35px":"36px"
                          }
                        }}
                        displayEmpty
                        onOpen={handleOpenSelect}
                      >
                          {data.map(item => {
                            return renderItem(item,{
                              className:webStyle.selectItemValuestyle
                            });
                          })}

                      </Select>
                </FormControl>
                {error && <p className='error'>{error}</p>}
        </div>
     );
}
CustomSelect.defaultProps = {
  // marginBottom: "25px"
}
export default CustomSelect;