import * as Yup from "yup";
import moment from "moment";
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
import { checkMobileNumberLength } from "../../../components/src/Scale";
const calculateAge = (dob:string) => {
  const now = moment();
  const birthDate = moment(dob);
  const years = now.diff(birthDate, "years");
  return years>=18;
};

export const isValidPhoneNumber = (mobile_number: string, id: CountryCode): boolean => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(mobile_number, id);
    return !!parsedPhoneNumber && parsedPhoneNumber.isPossible();
  } catch (error) {
    console.log(error,"error")
    return false
  }
};
export const basicInfoSchema = Yup.object({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  gender: Yup.string().required("Please select gender"),
  mobile_number: Yup.string()
    .test('mobile_number', 'Mobile number must be between 6 to 12 digits', function (value) {
      return checkMobileNumberLength(value, this.parent?.dialCode?.length)
    })
    .required('Please enter your mobile number'),
  address: Yup.string().required("Please enter address line 1")
                      .min(10,"Address line 1 should have minimum 10 and maximum 150 characters")
                      .max(150,"Address line 1 should have minimum 10 and maximum 150 characters")
                      .test('is-valid-address', 'Please enter valid address', (value) => {
                        if (!value) return true;
                        const totalLength = value.length;
                        const alphanumericCount = (value.match(/[a-zA-Z0-9]/g) || []).length;
                        return alphanumericCount > totalLength / 2;
                      }),
  address_line_2: Yup.string().required("Please enter address line 2") 
                      .min(10,"Address line 2 should have minimum 10 and maximum 150 characters")
                      .max(150,"Address line 2 should have minimum 10 and maximum 150 characters")
                      .test('is-valid-address', 'Please enter valid address', (value) => {
                        if (!value) return true;
                        const totalLength = value.length;
                        const alphanumericCount = (value.match(/[a-zA-Z0-9]/g) || []).length;
                        return alphanumericCount > totalLength / 2;
                      }),
  country: Yup.string().nullable().required("Please select country"),
  countryCode:Yup.string(),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  postal_code: Yup.string().required("Please enter postal code").min(3,"Postal code should have minimum 3 digits.").max(10,"Postal code should not have more more than 10 digits."),
  date_of_birth: Yup.string().required("Please select birthdate").test("birthday","DOB should not be later than today",function(val){
    return new Date() >= new Date(val as string)
  }).test("birthday", "Age must be over 18 years", function(val) {
    return calculateAge(val as string);
}),
  time_zone: Yup.string().required("Please enter timezone"),
  currency: Yup.string().required("Please select your currency"),
  dialCode: Yup.string()
});

export const sponsorvalidationSchema = Yup.object({
  hobby_ids: Yup.array().min(1, "Please select atleast one category"),
  company_name: Yup.string().notRequired(),
  website: Yup.string().notRequired(),
  industry: Yup.string().notRequired(),
  number_of_employee: Yup.string().notRequired(),
  mobile_number: Yup.string().notRequired()
    .test('mobile_number', 'Mobile number must be between 6 to 12 digits', function (value) {
      return checkMobileNumberLength(value, this.parent.dialCode.length)
    })
    .required('Please enter your mobile number'),
  vat_id: Yup.string().required("Please enter vat id"),
  address1: Yup.string().required("Please enter your address"),
  dialCode: Yup.string()
});

const creerExperienceSchema=Yup.object().shape(
  {
    employerName: Yup.string().required("Please enter employer name"),
    jobTitle: Yup.string().required( "Please enter job title"),
    country: Yup.string().required("Please select country"),
    city: Yup.string().required("Please select city"),
    state: Yup.string().required("Please select state"),
    jobDescription: Yup.string().required("Please enter job description"),
    currently_working_here: Yup.boolean().notRequired(),
  }
)

export const freelancerIndiviualValidationSchema = Yup.object({
  skill_ids: Yup.array().min(1, "Please select atleast one skill"),
  hobby_ids: Yup.array().min(1, "Please select atleast one category"),
  certification: Yup.string().notRequired(),
  experience_in_years: Yup.string()
  .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
    if (Number(value) <= 60) {
      return true
    }
    return false
  }),

  educational_qualifications: Yup.array(
    Yup.object({
      education: Yup.string().required("Please select education"),
      college: Yup.string().required("Please select university/college"),
      field: Yup.string().required("Please select field of study"),
    })
  ),
  career_experiences: Yup.array()
    .default([])
    .when('experience_in_years', {
      is: (experience_in_years) => experience_in_years && Number(experience_in_years) > 0,
      then: Yup.array(creerExperienceSchema),
      otherwise: Yup.array().notRequired(),
    })
});

export const corporateFreelancerValidationSchema = Yup.object({
  company_name: Yup.string().required("Please enter company name"),
  year_Regis: Yup.number()
    .required("Please enter year of registration")
    .typeError("Year of Registration must be a number.")
    .integer("Please enter a whole number")
    .test(
      "is-number",
      "Please enter a valid number",
      value => !isNaN(Number(value))
    )
    .min(1900, "Year of Registration cannot be before 1900.")
    .max(
      new Date().getFullYear(),
      `Year of Registration cannot be in the future.`
    )
    .required("Year of Registration is required."),
  companyReg_no: Yup.string().notRequired(),
  country: Yup.string().required("Please select country"),
  city: Yup.string().required("Please select city"),
  state: Yup.string().required("Please select state"),
  postal_code: Yup.string().required("Please enter postal code").min(3,"Postal code should have minimum 3 digits.")
    .max(10,"Postal code should not have more more than 10 digits."),
  company_profile: Yup.string().notRequired(),
  company_website: Yup.string().notRequired(),
  award_certificate: Yup.string().notRequired(),
  experience_in_years: Yup.string()
  .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
    if (Number(value) <= 60) {
      return true
    }
    return false
  }),
  skill_ids: Yup.array()
    .min(1, "Please select atleast one skill")
    .nullable(),
  hobby_ids: Yup.array()
    .min(1, "Please select atleast one category")
    .nullable(),
  company_overview: Yup.string()
    .required("Please enter company overview"),  
  number_of_employees: Yup.string()
    .required("Please enter number of employees"),
  type_of_industry: Yup.string()
    .required("Please select industry"),
  tax_id: Yup.string()
    .required("Please enter vat id"),
  certifications: Yup.array()
    .nullable(),
    
    
});

export const studentFreelancerValidationSchema = Yup.object({
  birthDate: Yup.date().notRequired(),
  skill_ids: Yup.array().min(1, "Please select atleast one skill"),
  hobby_ids: Yup.array().min(1, "Please select atleast one category"),
  certification: Yup.string().required("Please enter certifications"),
  experience_in_years: Yup.string()
  .test('experience_in_years', 'Experience must be less than or equal to 60 years.', function(value) {
    if (Number(value) <= 60) {
      return true
    }
    return false
  }),
  educational_qualifications: Yup.array(
    Yup.object({
      birthDate: Yup.date().notRequired(),
      education: Yup.string().required("Please select education"),
      college: Yup.string().required("Please select university/college"),
      field: Yup.string().required("Please select field of study"),
      start_year: Yup.date().required("Please enter start date")
                             .min(Yup.ref('birthDate'),"Start date should be greater than birthdate")
                             .max(Yup.ref('end_year'), 'Start date should be lower than end date'),
      end_year: Yup.date().required("Please enter end date").min(Yup.ref('start_year'), 'End date should be greater than start date'),
      isCollegeIdRequired: Yup.boolean().notRequired(),
      current_study: Yup.boolean().notRequired()
    })
  ),
  college_id: Yup.string().when('educational_qualifications', {
    is: (educational_qualifications) =>
      educational_qualifications||[].some((qual: {isCollegeIdRequired: boolean; current_study: boolean}) => qual.isCollegeIdRequired && qual.current_study),
    then: Yup.string().required("Please enter college ID"),
    otherwise: Yup.string().notRequired(),
  }),
  student_id: Yup.mixed().default(null).when('educational_qualifications', {
    is: (educational_qualifications) =>
      educational_qualifications||[].some((qual: {isCollegeIdRequired: boolean; current_study: boolean}) => qual.isCollegeIdRequired && qual.current_study),
    then: Yup.mixed()
      .required("Please upload a file")
      .test(
        "fileSize",
        "File size is too large. Max 10MB.",
        (value) => !value || (value && value.size <= 10 * 1024 * 1024)
      )
      .test(
        "fileType",
        "Unsupported file format. Only .DOC, .DOCX, .PDF allowed.",
        (value) =>
          !value ||
          (value && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(value.type))
      ),
    otherwise: Yup.mixed().notRequired(),
  }),
  career_experiences: Yup.array()
    .default([])
    .when('experience_in_years', {
      is: (experience_in_years) => experience_in_years && Number(experience_in_years) > 0,
      then: Yup.array(creerExperienceSchema),
      otherwise: Yup.array().notRequired(),
    })
});