import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IProfile } from "../../rolesandpermissions/src/Roles.types";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify"
import CreateRestApiMessage from "../../../components/src/CreateRestApiMessage.web";
import { IProjectData } from "../../dashboard/src/types";

interface IFile {
  url: string;
  name: string;
  size: number;
}

interface IMessage {
  id: number;
  msgStatus: string;
  message: string;
}
interface IInvoice {
  id: number;
  name: string;
  url: string;
}
interface IMilestonesDetails {
  created_at: string;
  description: string[];
  dispute_request_status: string;
  flag_title:string;
  is_rated: boolean;
  milestone_title: string;
  profile_id: number
  profile_pic: {
    url: string
  };
  project_closure_status: string;
  reason_for_refund: string;
  refund_id: number;
  refund_status: string;
  title: string;
  user_name: string;
}
export interface Milestone {
  id: string;
  type: string;
  edit?: boolean;
  errors?: any;
  attributes: {
    id: number;
    milestone_title: string;
    due_date: string;
    deposite_amount: number;
    milestone_status: string;
    project_id: number;
    milestone_description: string;
    progress_percentage: number;
    withdrawal_ready:boolean;
    created_at: string;
    updated_at: string;
    documents: IFile[];
    is_delayed: boolean;
    paid_status: boolean;
    total_cost: string;
    hours: number | string;
    milestone_sequence: number | string;
    invoice?: IInvoice[],
    currency_conversion?: {
      sponsor: {
          currency: string,
          currency_symbol:  string,
          amount: number
      },
      freelancer: {
          currency:  string,
          currency_symbol:  string,
          amount: number,
      }
   }

  };
}
const sampleMessageData = [
  {
    id: 1,
    msgStatus: "Message Sent",
    message:
      "Hey Amin Mohamed, Please try to complete the first milestopne by 10th sep. Thanks!",
  },
  {
    id: 2,
    msgStatus: "Message Recieved",
    message: "Sure, John",
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: { [key: string]: string };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  projectTitle: string;
  token: string;
  userName: string;
  role: string;
  location: string;
  time: string;
  selectedTab: number;
  paymentStatus: string;
  paymentAmount: string;
  originalAmount: string;
  amountRequested: string;
  isRevisionDetailsButtonClicked: boolean;
  addNewMilestoneTitle: string;
  addNewMilestoneDescription: string;
  addNewMilestoneDueDate: string;
  addNewMilestoneAmount: string;
  isAddnewMilestoneBtnClicked: boolean;
  messageData: IMessage[];
  additionalBonus: string;
  amountReleased: string;
  isAdditionalBonusAdded: boolean;
  isPaymentApproved: boolean;
  authToken: string;
  currentUser: IProfile | null;
  allMilestones: Milestone[];
  projectId: number | null;
  revisonDetails: string
  revisionFile: any;
  revisionDetailsFormId: null | number;
  paymentFormId: null | number;
  loader: boolean;
  prjLoader:boolean;
  milestoneDetails: any;
  milestoneFlags: string[];
  detailsLoader: boolean;
  loading: boolean;
  showModal: boolean;
  reasons: string[];
  reason: string;
  option: string;
  rating: number;
  feedback: string;
  showRatingModal: boolean;
  updateStatus: string;
  projectInfo: IProjectData | null;
  lancerId: string;
  projectStatus: string;
  editMileston: Milestone | null;
  createNewMileston: boolean;
  saveMilestoneLoading: boolean;
  lancerData: any;
  loaderId: number;
  arbitrationDialog: boolean,
  arbitrationFiles: any[],
  closureRequestStatus: string | null;
  completedMilestones: number;
  firstPendingMilestone: number;
  showReasonValidation: boolean;
  submitEvidenceId: number | null;
  refundId: null | number;
  arbitrationAmount:string;
  evidenceFileError:string;
  ratingLoader:boolean;
  ratingError:string;
  resumeLoader:boolean;
  arbitrationLoader:boolean;
  download:{
    invoiceDownloadMilestoneId:string;
    receiptDownloadMilestoneId:string;
    invoiceDownloadLoading:boolean;
    receiptDownloadLoading:boolean;
  }
  milestoneLoader:boolean;
  currencysymbol: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  getAllMileStonesId: string = "";
  createNewMilestoneId: string = ''
  revisionMilestoneId: string = "";
  approvePaymentAPIcallId: string = "";
  requestRevisionAPIcallId: string = "";
  projectDetailsApiCallId: string = "";
  milestoneDetailsApiCallId: string = "";
  changeProjectStatusAPIcallId: string = "";
  resumeProjectStatusAPIcallId: string = "";
  createChatApiCallId: string = "";
  rateLancerAPIcallId: string = "";
  createNewMilestoneApiCallId: string = "";
  refundRequestAPICallId: string = "";
  submitEvidenceAPICallId: string = "";
  arbitrationAmountApiCallId: string = "";
  resumeDisputeProjectAPIcallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.LoginUserMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      projectTitle: "",
      token: "",
      userName: "",
      role: "",
      location: "",
      time: "Local Time: 1:13 PM",
      selectedTab: 0,
      paymentStatus: "Payment requested",
      paymentAmount: "$10.00 paid from MasterCard ending in 1371 on Jul 24",
      originalAmount: "$10.00",
      amountRequested: "$10.00",
      isRevisionDetailsButtonClicked: false,
      addNewMilestoneTitle: '',
      addNewMilestoneDescription: "",
      addNewMilestoneDueDate: "",
      addNewMilestoneAmount: "",
      isAddnewMilestoneBtnClicked: false,
      messageData: sampleMessageData,
      additionalBonus: "",
      amountReleased: '$17.00',
      isAdditionalBonusAdded: false,
      isPaymentApproved: false,
      authToken: "",
      currentUser: null,
      allMilestones: [],
      projectId: null,
      revisonDetails: '',
      revisionFile: [],
      revisionDetailsFormId: null,
      paymentFormId: null,
      loader: true,
      prjLoader:true,
      milestoneDetails: {},
      milestoneFlags: ['Bid accepted', 'Milestone Created', 'Message Sent', 'Message Received', 'File Uploaded',
        'Requested for revision', 'Completed Project', "Project Closed", "Project On Hold", "Project Closure Requested",
        "Project Closure Rejected", "Project Closure Accepted", "Dispute has been Raised for Project",
        "Dispute Request for Project is Resolved", "Dispute Request for Project is Rejected", "Refund Request Send",
        "Refund Request Accept", "Refund Request Reject", "Dispute has been Raised", "Dispute Request Resolved",
        "Dispute Request Rejected","Project has been resumed successfully"],
      detailsLoader: true,
      loading: false,
      showModal: false,
      reason: "",
      option: "",
      reasons: [
        "Not satisfied with lancer's work",
        "Lancer is not communicating well",
        "Lancer is not responding",
        "Payment not initiated for the submitted files",
        "",
      ],
      showRatingModal: false,
      rating: 0,
      feedback: '',
      updateStatus: '',
      projectInfo: null,
      lancerId: "",
      projectStatus: "",
      editMileston: null,
      createNewMileston: false,
      saveMilestoneLoading: false,
      lancerData: {},
      loaderId: 0,
      arbitrationDialog: false,
      arbitrationFiles: [],
      closureRequestStatus: null,
      completedMilestones: 0,
      firstPendingMilestone: 0,
      showReasonValidation: false,
      submitEvidenceId: null,
      refundId: null,
      arbitrationAmount:"",
      evidenceFileError:'',
      ratingLoader:false,
      ratingError:"",
      resumeLoader:false,
      arbitrationLoader:false,
      download:{
        invoiceDownloadLoading:false,
        invoiceDownloadMilestoneId:"",
        receiptDownloadLoading:false,
        receiptDownloadMilestoneId:""
      },
      milestoneLoader:false,
      currencysymbol:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (message.id === getName(MessageEnum.LoginUserMessage)) {
      const user = message.getData(getName(MessageEnum.LoginUserBodyMessage));
      if(user){
        this.setState({ currentUser: user });
      }
    } 
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.handleResponse(apiRequestCallId, responseJson)
      }
      else if (responseJson?.errors) {
        if (apiRequestCallId === this.getAllMileStonesId) {
          this.parseApiErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData("loginSuccessToken");
    const projectId = this.props.navigation.getParam("id")
    this.getCurrentUserMessage();
    const status = this.props.navigation.getParam("status")
    this.setState({ authToken, projectId: Number(projectId), projectStatus: status }, () => {
      this.getAllMilestones();
      this.getMilestoneDetails();
    });
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    if (prevState.selectedTab !== this.state.selectedTab) {
      if(this.state.selectedTab==0){
        this.getAllMilestones();
      }
      else{
        this.getMilestoneDetails();
      }
    }
  }

  getCurrentUserMessage = () => {
    const msg: Message = new Message(
      getName(MessageEnum.GetCurrentUserMessage)
    );
    this.send(msg);
  }

  handleAllMilestonesResponse = (data: Milestone[]) => {
    let completedMilestones = 0
    let firstPendingMilestone = 0
    const formattedData = {
      data: data.map(item => {

        if (item.attributes.milestone_status === "completed") {
          completedMilestones = completedMilestones + 1
        }
        if (item.attributes.milestone_status === "pending" && firstPendingMilestone === 0) {
          firstPendingMilestone = item.attributes.id
        }

        return ({
          ...item,
          attributes: {
            ...item.attributes,
            due_date: new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            }).format(new Date(item.attributes.due_date))
          }
        })
      }
      )
    };
    this.setState({
      allMilestones: formattedData.data,
      completedMilestones: completedMilestones,
      firstPendingMilestone: firstPendingMilestone
    })
  }
  handleEditMilestone = (milestone: Milestone | null, edit: boolean) => {
    this.setState(prev => ({
      createNewMileston: false,
      editMileston: milestone,
      allMilestones: prev.allMilestones?.map((item) => {
        if (item?.id === milestone?.id) {
          return {
            ...item,
            edit: edit
          }
        }
        return {
          ...item,
          edit: false
        };
      })
    }))
  }
  handleCreateNewMilestone = () => {
    const id = new Date().getTime();
    const newMilestone: Milestone = {
      id: id.toString(),
      type: "milestone",
      attributes: {
        created_at: "",
        deposite_amount: 0,
        documents: [],
        due_date: "",
        hours: "",
        id: id,
        is_delayed: false,
        milestone_description: "",
        milestone_sequence: "",
        milestone_status: "",
        milestone_title: "",
        progress_percentage: 0,
        project_id: 1,
        total_cost: "",
        updated_at: "",
        paid_status: false,
        withdrawal_ready:false,
      }
    }
    this.setState(prev => ({
      createNewMileston: true,
      allMilestones: prev.allMilestones?.map((item) => ({ ...item, edit: false })),
      editMileston: newMilestone
    }))
  }

  navigateToWithdrawMilestone=(id:string)=>{
      this.props.navigation.navigate("SponsorWithdraw",{
        milestoneId:id
      })
  }

  handleChangeEditMilestone = (key: string, value: string) => {

    const { editMileston } = this.state;

    if (!editMileston) {
      return;
    }

    const newMilestone: Milestone = {
      ...editMileston,
      attributes: {
        ...editMileston.attributes,
        [key]: value
      }
    }
    this.setState({
      editMileston: newMilestone
    })


  }
  handleMilestoneSubmit = () => {
    const { editMileston, createNewMileston, projectInfo, authToken } = this.state;
    if (!editMileston) {
      return;
    }
    const result = this.validateMilestone();

    if (!result?.isValid) {
      const newMilestoneWithErrors = { ...editMileston, errors: result.errors }
      this.setState({ editMileston: newMilestoneWithErrors })
      return;
    }

    this.setState({ saveMilestoneLoading: true })

    const formData = new FormData();

    const depositeAmount = Number(projectInfo?.attributes?.accepted_amount) * Number(editMileston?.attributes?.hours);
    formData.append("project_milestone[milestone_title]", editMileston?.attributes?.milestone_title);
    formData.append("project_milestone[milestone_description]", editMileston?.attributes?.milestone_description);
    formData.append("project_milestone[deposite_amount]", depositeAmount?.toString());
    formData.append("project_milestone[hours]", editMileston?.attributes?.hours?.toString());
    formData.append("project_milestone[milestone_sequence]", editMileston?.attributes?.milestone_sequence?.toString());

    let hitUrl = "";
    let method = "";
    if (createNewMileston) {
      formData.append("project_milestone[project_id]", projectInfo?.id?.toString() || "");
      hitUrl = "bx_block_scheduling/project_milestones"
      method = "POST"
    } else {
      hitUrl = `bx_block_scheduling/project_milestones/${editMileston?.id}`
      method = "PUT"
    }
    const requestMessage = CreateRestApiMessage({
      apiUrl: hitUrl,
      body: formData,
      method: method,
      header: {
        token: authToken
      }
    })
    this.createNewMilestoneApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  validateMilestone = () => {
    const { editMileston, allMilestones } = this.state;
    let newErrors: any = {};
    if (!editMileston?.attributes?.milestone_title?.trim()) {
      newErrors.milestone_title = "Please enter milestone title"
    }
    if (!editMileston?.attributes?.milestone_description?.trim()) {
      newErrors.milestone_description = "Please enter milestone description"
    }
    if (!editMileston?.attributes?.hours?.toString()?.trim()) {
      newErrors.hours = "Please enter milestone required hours"
    }
    if (!editMileston?.attributes?.milestone_sequence?.toString()?.trim()) {
      newErrors.milestone_sequence = "Please enter milestone sequence"
    }

    const sequence = editMileston?.attributes?.milestone_sequence?.toString()?.trim() as string;
    const integerRegex = /^\d+$/;
    if (!integerRegex.test(sequence) && sequence) {
      newErrors.milestone_sequence = "Milestone sequence should be integer only"
    }




    //check the sequence already taken or not
    const isSequenceTaken = allMilestones?.filter((item) => item?.attributes?.milestone_sequence?.toString() === editMileston?.attributes?.milestone_sequence?.toString() && editMileston?.id !== item?.id)?.length > 0;

    if (isSequenceTaken) {
      newErrors.milestone_sequence = "Milestone sequence already exists"
    }


    if (Object.keys(newErrors).length > 0) {
      return {
        isValid: false,
        errors: newErrors
      }
    }

    return {
      isValid: true,
      errors: {}
    }


  }
  handleMilestoneCancel = () => {
    const { editMileston } = this.state;
    if (editMileston) {
      this.setState(prev => ({
        allMilestones: prev.allMilestones?.map((item) => ({ ...item, edit: false })),
        editMileston: null,
        createNewMileston: false
      }))
    }
  }
  handleInvoiceDownload = (milestone: any) => {
    if(!milestone?.attributes?.invoice){
      return this.handleShowErrorForMilestone.bind(this,"Invoice Not Found.");
    }
    return this.handleDownloadFile.bind(this, milestone.attributes?.invoice[0]?.url, milestone.attributes?.invoice[0]?.name,milestone?.id,"invoice")
  } 
  handleReceiptDownload = (milestone: any) => {
    if(!milestone?.attributes?.receipt){
      return this.handleShowErrorForMilestone.bind(this,"Receipt Not Found.");
    }
    return this.handleDownloadFile.bind(this, milestone.attributes?.receipt[0]?.url, milestone.attributes?.receipt[0]?.name,milestone?.id,"receipt")
  }
  handleShowErrorForMilestone=(message:string)=>{
    toast.error(message)
  }
  // Tabs handler
  handleTabSelect = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 2) {
      this.onSendMessage();
      return;
    }
    this.setState({ selectedTab: newValue });
  };

  handleCancelRevisionScreen = () => {
    this.setState({ revisionDetailsFormId: null, revisionFile: [] });
  };

  handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target
    this.setState({ ...this.state, [name]: value });
  }

  addNewMilestone = () => {
    this.setState({ isAddnewMilestoneBtnClicked: !this.state.isAddnewMilestoneBtnClicked });
  }

  addAdditionalBonus = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isAdditionalBonusAdded: event.target.checked })
  }

  handleApprovePayment = () => {
    this.setState({ isPaymentApproved: !this.state.isPaymentApproved });
  };

  handleFileDrop = (acceptedFiles: any) => {
    this.setState({evidenceFileError:''})
    if (acceptedFiles) {
      let sizeExceedsLimit = false;
      let newDocs :File[]= [...this.state.arbitrationFiles]; 
      let revisonFiles :File[]= [...this.state.revisionFile]; 
  
      acceptedFiles.forEach((doc_file: File) => {
  
          if (doc_file.size > 15 * 1024 * 1024) { 
           sizeExceedsLimit = true;
         } else if(
          doc_file.name.toLowerCase().endsWith('.app')
        ){
          toast.error(".app is invalid file");
          return false;
        }
        else {
          newDocs.push(doc_file); 
          revisonFiles.push(doc_file); 
        }
      });
  
      if (sizeExceedsLimit) {
        this.setState({evidenceFileError:configJSON.errorTxtMaxUpload})
      } 
      else {
        this.setState({ 
          revisionFile: revisonFiles, 
          arbitrationFiles: newDocs,
          showReasonValidation:false
         })
      }
   }
    
  }

  handleDeleteUploadedFile = (fileIndex:number) => {
    const updatedFiles = this.state.revisionFile.filter(
      (file:File, index:number) => index !== fileIndex
    );
    this.setState({ revisionFile: updatedFiles })
  }
  handleDeleteFile = (instance:any,ind:number)=>{
    this.state.arbitrationFiles.splice(ind,1)
    this.setState({arbitrationFiles:[...this.state.arbitrationFiles]})
    }

  validateRevisionRequest = (milestone_id: number) => {
    if (this.state.revisionFile.length > 0) {
      this.handleRequestRevision(milestone_id)
      return
    }
    toast.error("Please attach a File.")
  }

  handleShowModal = () => {
    this.setState({ showModal: !this.state.showModal, option: '', reason: "", showReasonValidation: false,refundId:null })
  }

  handleRadioChange = (e: any) => {
    if (this.state.showReasonValidation) {
      this.setState({ showReasonValidation: false })
    }
    this.setState({ option: e.target.value, reason: this.state.reasons[+(e.target.value) - 1] })
  }

  handleReasonInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ reason: e.target.value })
  }

  handleRatingChange = (newRating: any) => {
    this.setState({ rating: newRating, ratingError:'' })
  }

  handleShowRatingModal = (modalType: string) => {
    if (modalType === "rating") {
      this.setState({ showRatingModal: !this.state.showRatingModal, feedback: '', rating: 0 , ratingError:''})
    }
    else {
      this.setState({arbitrationDialog: false, arbitrationFiles: [],showReasonValidation:false,evidenceFileError:'' })
    }
  }

  handleProjectUpdateStatus = (status: string) => {
    this.setState({ showModal: true, updateStatus: status, showReasonValidation: false })
  }

  handleReviewChange = (event: any) => {
    this.setState({ feedback: event.target.value })
  }

  handleStartArbitrationProcess = (milestoneId: number) => {
    this.setState({ arbitrationDialog: true, submitEvidenceId: milestoneId })
    this.getArbitrationAmount()
  }

  handleRefund = (milestoneId: String) => {
    this.setState({ refundId: Number(milestoneId), showModal: true,updateStatus:"refund" })
  }

  onSendMessage = async () => {
    const { authToken, currentUser, projectId, projectInfo } = this.state;
    const header = {
      token: authToken,
      "Content-Type": "application/json",
    };
    const loggedInUserId = currentUser?.attributes?.account_detail?.data?.id || "";
    const freelancerId = projectInfo?.attributes?.Hired_freelancers?.account_id || "";
    const body = {
      chat: {
        name: freelancerId + loggedInUserId,
        account_id: loggedInUserId,
        user_id: freelancerId,
        user_type: "sponsor",
        other_user_type: "freelancer",
        project_id: projectId
      }
    }
    const apiRequestMessage = CreateRestApiMessage({
      header,
      apiUrl: `${configJSON.createConversationApiUrl}`,
      body: JSON.stringify(body),
      method: "POST",
    });

    this.createChatApiCallId = apiRequestMessage.messageId;
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
  }

  getAllMilestones = () => {
    this.setState({milestoneLoader:true})
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };
    const getAllMileStonesRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllMileStonesId = getAllMileStonesRequest.messageId;

    getAllMileStonesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllMilestonesEndpoint}${this.state.projectId}`
    );
    getAllMileStonesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getAllMileStonesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getAllMileStonesRequest.id, getAllMileStonesRequest);
  };

  createNewMileStone = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };

    const httpBody = {
      project_id: this.state.projectId,
      milestone_title: this.state.addNewMilestoneTitle,
      milestone_description: this.state.addNewMilestoneDescription,
      due_date: this.state.addNewMilestoneDueDate,
      deposite_amount: this.state.addNewMilestoneAmount,
    };
    const createNewMilestoneRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewMilestoneId = createNewMilestoneRequest.messageId;

    createNewMilestoneRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewMilestoneEndpoint
    );
    createNewMilestoneRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    createNewMilestoneRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    createNewMilestoneRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(
      createNewMilestoneRequest.id,
      createNewMilestoneRequest
    );
  };

  approvePayment = (milestoneId: any, paid: boolean) => {

    this.setState({ loading: true, loaderId: Number(milestoneId) })
    const header = {
      token: this.state.authToken
    };

    let formdata = new FormData();
    formdata.append("milestone_id", milestoneId);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.approvePaymentAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.approvePaymentAPIEndpoint

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePayForMilestone = (milestoneId: string) => {
    this.props?.navigation?.navigate("PayMilestone", {
      milestoneId: milestoneId,
      projectId: this.state.projectInfo?.id
    })

  }

  handleRequestRevision = (milestoneId: number) => {
    this.setState({ revisionDetailsFormId: milestoneId, loading: true })

    const header = {
      token: this.state.authToken
    }

    let formdata = new FormData();
    this.state.revisionFile?.forEach((file:File)=>{
      formdata.append('images[]', file)
    })
    formdata.append('project_milestone[revision_details]', this.state.revisonDetails)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.requestRevisionAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/project_milestones/${milestoneId}/request_revision`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getMilestoneDetails = () => {
    const header = {
      token: this.state.authToken,
      "Content-Type": "application/json",
    };
    const getAllMileStonesRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.milestoneDetailsApiCallId = getAllMileStonesRequest.messageId;

    getAllMileStonesRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.milestonesDetailsApiCallUrl}${this.state.projectId}`
    );
    getAllMileStonesRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    getAllMileStonesRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(getAllMileStonesRequest.id, getAllMileStonesRequest);
  };



  onConvertFileSize = (bytes: number) => {

    if (bytes >= 1024 * 1024) {
      return (`(${(bytes / (1024 * 1024)).toFixed(2)}MB)`);
    } else {
      return (`(${(bytes / 1024).toFixed(2)}KB)`);
    }
  }

  handleDownloadFile = async (url: string, fileName: string,milestoneId?:string,type?:string) => {
     if(milestoneId && type){
      this.handleDownloadLoading(true,milestoneId,type);
     }
    const response = await fetch(url);
    const blob = await response.blob();
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    if(type && milestoneId){
      this.handleDownloadLoading(false,"",type);
    }
  };

  handleDownloadLoading=(enabled:boolean,milestoneId:string,type:string)=>{
    this.setState((prev)=>{
      const updatedDownload={
        ...prev.download
      }
      if(type==="invoice"){
         updatedDownload.invoiceDownloadLoading=enabled;
         updatedDownload.invoiceDownloadMilestoneId=milestoneId;
      }else{
        updatedDownload.receiptDownloadLoading=enabled;
        updatedDownload.receiptDownloadMilestoneId=milestoneId;
      }
      return {download:updatedDownload}
   })
  }

  handleChangeProjectStatus = () => {

    if (!this.state.reason) {
      this.setState({ showReasonValidation: true })
      return
    }
    if (this.state.updateStatus === "closed") {
      this.handleEndProject()
    }
    else if (this.state.updateStatus === "refund") {
      this.handleSubmitRefundRequest()
    }
    else {
      this.handlePutProjectOnHold()
    }
  }

  handlePutProjectOnHold = () => {
    this.setState({ showModal: true, loading: true })
    const header = {
      token: this.state.authToken
    }

    const params = String(this.state.projectId)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeProjectStatusAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.onholdProjectEndpoint}?id=${params}&reason_for_onhold=${this.state.reason}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleEndProject = () => {
    this.setState({ showModal: true, loading: true })
    const header = {
      token: this.state.authToken
    }

    let formdata = new FormData();
    formdata.append('id', String(this.state.projectId))
    formdata.append('status', this.state.updateStatus)
    formdata.append('reason_for_closure', this.state.reason)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeProjectStatusAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeProjectStatusEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleResumeProject = () => {
    const header = {
      token: this.state.authToken
    }
this.setState({resumeLoader:true})
    const params = String(this.state.projectId)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resumeProjectStatusAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resumeProjectEndpoint}?id=${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleSponsorValidateRatings=()=>{
    const {rating} = this.state
    if (rating==0){
        this.setState({ratingError: configJSON.ratingErrorMsg})
      }
    else{
      this.rateLancer()
    }
   }
  rateLancer = () => {
    const { rating, lancerId, projectId, feedback } = this.state
    this.setState({ratingLoader:true, ratingError:''})
    const header = {
      token: this.state.authToken
    }

    let formdata = new FormData();
    formdata.append('review[score]', String(rating))
    formdata.append('review[description]', feedback)
    formdata.append('review[profile_id]', lancerId)
    formdata.append('review[project_id]', String(projectId))
    formdata.append('review[rated_from]', 'sponsor')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rateLancerAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rateLancerAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCreateNewMilestonResponse = (responseJson: any) => {
    const { createNewMileston } = this.state;
    this.setState({ saveMilestoneLoading: false })
    if (responseJson?.data?.id) {
      toast.success(createNewMileston ? "Milestone created" : "Milestone updated")
      this.setState({
        createNewMileston: false,
        editMileston: null
      })
      this.getAllMilestones();
      this.getMilestoneDetails();
    } else {
      toast.error(createNewMileston ? "Milestone create failed!" : "Milestone update failed")
    }
  }


  handleSendMessageResponse = (responseJson: any) => {
    if (responseJson?.data?.id) {
      const chatMessage: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      chatMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Chat"
      );
      chatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
        responseJson,
      });
      chatMessage.addData(
        getName(MessageEnum.NavigationRaiseMessage),
        raiseMessage
      );
      this.send(chatMessage);
    }
  }

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.projectDetailsApiCallId) {
      const attributes = responseJson.data.attributes
      const hiredLancer = responseJson.data.attributes?.Hired_freelancers
      const freelancerPhoto = responseJson.data.attributes?.Hired_freelancers_photo?.url
      const closureRequestStatus = responseJson.data.attributes.project_clouser_status
      this.setState({
        prjLoader: false, lancerData: {
          projectTitle: attributes.project_name,
          lancerId: hiredLancer?.id,
          userName: hiredLancer?.full_name,
          role: hiredLancer?.sub_role,
          location: hiredLancer?.city + ', ' + hiredLancer?.country,
          timeZone: hiredLancer?.time_zone,
          lancerPhoto: freelancerPhoto,
          projectStatus: attributes.project_status,
        },
        lancerId: hiredLancer?.id,
        projectInfo: responseJson?.data,
        closureRequestStatus: closureRequestStatus,
        currencysymbol: responseJson.data.attributes.currency_conversion.freelancer.currency_symbol
      })
    }
    if (apiRequestCallId === this.getAllMileStonesId) {
      this.fetchProjectDetails(responseJson?.data[0]?.attributes?.freelancer_id);
      this.handleAllMilestonesResponse(responseJson.data)
      this.setState({ loader: false, milestoneLoader: false})
    }
    if (apiRequestCallId === this.createNewMilestoneId) {
      this.setState({ isAddnewMilestoneBtnClicked: false });
    }
    if (apiRequestCallId === this.revisionMilestoneId) {
      this.setState({ isAddnewMilestoneBtnClicked: false });
    }
    if (apiRequestCallId === this.approvePaymentAPIcallId) {
      console.log("this is called", responseJson);
      this.setState({ paymentFormId: null, loading: false, loaderId: 0 })

      const { allMilestones, completedMilestones, projectStatus, projectId } = this.state

      this.getAllMilestones();
      if (allMilestones.length === completedMilestones + 1 && projectStatus === "projectinprogress") {
        toast.success("Milestone Approved and project moved to Completed Projects.")
        this.fetchProjectDetails(null);
        this.props.navigation?.navigate("Projects", { type: "scheduling", status: "completedproject", id: projectId })
        return
      }

      toast.success("Milestone Approved.")
    }
    if (apiRequestCallId === this.requestRevisionAPIcallId) {
      this.setState({ revisonDetails: "", revisionDetailsFormId: null, loading: false, revisionFile: [] })
      toast.success("Successfully Requested for Revision.")
      this.getAllMilestones()
    }
    if (apiRequestCallId === this.rateLancerAPIcallId) {
      this.setState({ rating: 0, showRatingModal: false, feedback: '', ratingLoader:false, })
      toast.success(responseJson.message)
      this.getMilestoneDetails()
    }
    if (apiRequestCallId === this.refundRequestAPICallId) {
      this.setState({ refundId: null, showModal: false,reason:"",loading:false,option:"" }, () => {
        toast.success("Refund request sent")
        this.getAllMilestones()
      })
    }
    else( this.handleResponseWithoutErrorsApis(apiRequestCallId, responseJson))
  }
  handleResponseWithoutErrorsApis(apiRequestCallId: string, responseJson: any) {
    if(apiRequestCallId === this.arbitrationAmountApiCallId){
      this.arbitrationAmountApiCall(responseJson)
    }
    else if(apiRequestCallId === this.submitEvidenceAPICallId){
     this.handleEvidenceSubmissionRes(responseJson)
    }
    else if (apiRequestCallId === this.milestoneDetailsApiCallId) {
      for (const date in responseJson){
        if (!!date?.length) {
          const projectFinishedEntries = responseJson[date].filter((entry:IMilestonesDetails) => entry.flag_title === "Project Finished")

          projectFinishedEntries.sort((a: IMilestonesDetails, b:IMilestonesDetails) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
          const uniqueFinishedEntries = new Set<string>()
          const uniqueProjectFinishedEntries = projectFinishedEntries.filter((entry:IMilestonesDetails) => {
            if(!uniqueFinishedEntries.has(entry.flag_title)) {
              uniqueFinishedEntries.add(entry.flag_title);
              return true;
            }
            return false;
          })
          const allEntries = responseJson[date].filter((entry:IMilestonesDetails) => entry.flag_title !== "Project Finished");
          responseJson[date] = [...allEntries, ...uniqueProjectFinishedEntries]
          responseJson[date].sort((a: IMilestonesDetails, b:IMilestonesDetails) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        }
      }
      this.setState({ detailsLoader: false, milestoneDetails: responseJson })
    }
    else if (apiRequestCallId === this.changeProjectStatusAPIcallId) {
      this.setState({ showModal: false, reason: '', loading: false, option: '' })
      const message = this.getProjectStatusUpdateMessage()
      toast.success(message)
      if (this.state.updateStatus === "on-hold") {
        this.props.navigation?.navigate("Projects", { type: "projectonhold" })
      }
    }
    else if (apiRequestCallId === this.resumeProjectStatusAPIcallId) {
      this.setState({ showModal: false, reason: '', loading: false, option: '', resumeLoader:false })
      const message = "Project Resumed successfully."
      toast.success(message)
      this.props.navigation?.navigate("Projects", { type: "projectinprogress" })
    }
    else if (apiRequestCallId === this.createChatApiCallId) {
      this.handleSendMessageResponse(responseJson);
    } else if (apiRequestCallId === this.createNewMilestoneApiCallId) {
      this.handleCreateNewMilestonResponse(responseJson);
    }

    else if (apiRequestCallId === this.resumeDisputeProjectAPIcallId) {
      if(responseJson){
        this.setState({ resumeLoader:false })
        const message = responseJson.message
        toast.success(message)
       this.handleresumenavigation(responseJson)
       }
       else{
        toast.error(responseJson.error)
       }
     }
  
  }

  arbitrationAmountApiCall=(responseJson:{ arbitration:{amount:number} })=>{
    if(responseJson){
      this.setState({arbitrationAmount:responseJson.arbitration.amount.toFixed(2)})
 }
 }

 handleEvidenceSubmissionRes=(responseJson:{payment_link:string})=>{
  if(responseJson?.payment_link){
    this.setState({
      arbitrationDialog:false,
      arbitrationFiles:[],
      arbitrationLoader:false,
    })

    const link = document.createElement('a');
    link.href = responseJson?.payment_link;
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
 }
 else
 {
  toast.error(configJSON.paymentErrorMsg)
 }
 }
 
  convertFileName = (name: string) => {
    if (name.length > 12) {
      const dotIndex = name.lastIndexOf('.');
      if (dotIndex !== -1) {
        const firstEight = name.substring(0, 7).trim();
        const hiddenPart = '*'.repeat(3);
        const extension = name.substring(dotIndex);
        return `${firstEight}${hiddenPart}${extension}`;
      } else {
        return name;
      }
    } else {
      return name;
    }
  }

  handleresumenavigation = (responseJson:any)=>{
    if(responseJson.project_status == "in-progress"){
      this.props.navigation?.navigate("Projects", { type: "projectinprogress" })
    }
    if(responseJson.project_status == "completed"){
      this.props.navigation?.navigate("Projects", { type: "completedproject" })
    }
  }

  getProjectStatusUpdateMessage = () => {

    const message = this.state.updateStatus === "closed" ? "Project closure request sent successfully."
      : "Project has been put on hold successfully."
    return message
  }

  handleSubmitRefundRequest = async () => {

    const header = {
      token: await getStorageData("loginSuccessToken", false)
    }

    const refundRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.refundRequestAPICallId = refundRequestMessage.messageId;

    refundRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.refundRequestAPIEndpoint}=${this.state.refundId}&${configJSON.reasonForRefund}=${this.state.reason}`
    );

    refundRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    refundRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(refundRequestMessage.id, refundRequestMessage);

  }

  handleSubmitEvidence = async () => {
    const { arbitrationFiles, submitEvidenceId } = this.state

    if(arbitrationFiles.length === 0){
      this.setState({showReasonValidation:true})
      return
    }
    this.setState({arbitrationLoader:true}) 
    const header = {
      token: await getStorageData("loginSuccessToken", false)
    }

    let formdata = new FormData();
    formdata.append('dispute_id', String(submitEvidenceId))
    arbitrationFiles.forEach((doc: any) => {
      formdata.append("submit_evidences[]", doc)
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submitEvidenceAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitArbitrationEvidenceAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getArbitrationAmount = async() => {
    const token = await getStorageData('loginSuccessToken', false)
    const header = {
      token,
      "Content-Type": undefined
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.arbitrationAmountApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.arbitrationAmountApiCallUrl + `?project_id=${this.state.projectId}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResumeDiputedProject = () => {
    const header = {
      token: this.state.authToken
    }
    this.setState({ resumeLoader:true })
    const projectId = String(this.state.projectId)

    let formdata = new FormData();

    formdata.append('project_id', projectId)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resumeDisputeProjectAPIcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resumeDisputeProjectEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  fetchProjectDetails = (freelancer_id: number | null) => {
    this.setState({prjLoader:true})
    const header = {
      token: this.state.authToken,
    };
    const fetchProjectDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectDetailsApiCallId = fetchProjectDetailsRequest.messageId;

    fetchProjectDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectDetailsAPIEndpoint}${this.state.projectId}`
    );
    fetchProjectDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    fetchProjectDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(fetchProjectDetailsRequest.id, fetchProjectDetailsRequest);
  };
  // Customizable Area End
}