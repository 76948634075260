import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Grid,
  InputLabel,
  Button,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab'
import { CustomPaper } from "../../../components/src/utill/CountryTextInput.web";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Dropzone from "react-dropzone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ThemeProvider,createTheme} from "@material-ui/core/styles";
import "../assets/css/Basic_details_customizable.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'  
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End

// Customizable Area Start
export const profileTheme = createTheme({
  palette: {
    },
  typography: {
      fontFamily:'inter',
    },
    overrides: {
      MuiInput: {
          root: {
              fontFamily: "Inter",
              // fontWeight: 700,
              fontSize: '14px',
              color:'#222',
          },
      }
  } 
  
});

const genderItems = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
  { value: "prefer_not_to_share", label: "Prefer not to share" },
];
// Customizable Area End

import Customisableuserprofiles2Controller, {
  BasicDetailsValidation,
  Props,
  configJSON
} from "./Customisableuserprofiles2Controller";
import CustomSelect from "../../../components/src/utill/CustomSelect.web";

export default class BasicDetailsCustomizable extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
   renderStateFieldOrSelect = ( values:any,errors:any) => {
    const { isLoadingStatesData, getEnabledStatesData, currentUserDetails } = this.state;
    const { stateTxt, placeholderState, loaderTxt } = configJSON;
  
    if (!isLoadingStatesData) {
      if (getEnabledStatesData.length === 0) {
        return (
          <>
            <InputLabel htmlFor="state">{stateTxt}</InputLabel>
            <Field
              fullWidth
              displayEmpty
              placeholder={placeholderState}
              onChange={this.onStateChange}
              name="state"
              data-testid="state"
              value={currentUserDetails.state}
              style={webStyle.InputFieldStyle}
            />
            <ErrorMessage
              name="state"
              component="div"
              className="error-class"
            />
          </>
        );
      } else {
        return (
          <>
            <div className="typography-label">State*</div>
            <Autocomplete
            value={values.state}
              PaperComponent={CustomPaper}
              onChange={(event: any, state: any) => {
                this.onStateChange({ target: { value: state } } as any);
              }}
            data-testid="select-state"
              options={getEnabledStatesData}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Select your state"
                  name="state"
                  className="custom-autocomplete-textfield"
                />
              )}
              id="state"
            />
            <ErrorMessage
              name="state"
              component="div"
              className="error-class"
            />
        </>
        );
        
      }
    } else {
      return (
        <p data-testid="statesLoaderText" style={webStyle.loaderTxtStyle}>
          {loaderTxt}
        </p>
      );
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      
      <ThemeProvider theme={profileTheme}>
      <Container  className="mainProfileDetails" >
      <style>
        {`
          @media(max-width: 959px) {
            .mainProfileDetails {
              padding: 0;
            }
          }
        `}
      </style>
        <Box className="profile-block">
          <Formik
          innerRef={this.formikRef}
          enableReinitialize={true}
            initialValues={this.state.currentUserDetails}
            onSubmit={values => {
              this.handleUpdateBasicDetails(values);
            }}
            
            validationSchema={BasicDetailsValidation}
            data-testid = 'formik'
          >
            {({errors,  resetForm , values,setFieldValue,setValues}) => (
              <Form translate={undefined}>
                <Box className="basic-details-main-container box-border">
                  <Box
                    className={
                      this.props.typeOfRole === "Freelancer"
                        ? "img-upload-block"
                        : "img-upload-block sponsor-background-color"
                    }
                  >
                    <Dropzone onDrop={this.handleDrop} data-testid = 'dropzoneFiles'
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="container" >
                          <div
                            {...getRootProps({ className: "drop-zone-block" })}
                          >
                            <input {...getInputProps()} />
                            {this.state.profilePicImgUrl ? (
                              <img
                                src={this.state.profilePicImgUrl}
                                style={webStyle.uploadImageStyle}
                              />
                            ) : (
                              <>
                              <p className="upload-image-text">
                                {configJSON.attachImgTxt} <br />
                                <span className="size-of-image">
                                  {configJSON.maxFileSize}
                                </span>
                              </p>
                              <EditIcon/>
                              </>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                   {this.props.typeOfRole === "Freelancer" ?<h6>{configJSON.showYourPhoto}</h6> : <h6>{configJSON.showYourFreelancerPhoto}</h6>} 
                    <p>{configJSON.mustBeactualPhotoTxt} </p>

                  </Box>
                  <Box className="row form-block">
                    <Grid container>
                      <Grid item xs={12} sm={6} className="field-group">
                        <InputLabel htmlFor="firstName">
                          {configJSON.firstNameTxt}
                        </InputLabel>
                        <Field
                          name="firstName"
                          fullWidth
                          placeholder={configJSON.firstNamePlaceholderTxt}
                          data-testid="firstName"
                          style={webStyle.fieldStyle}
                          />
                          <ErrorMessage
                          name="firstName"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className="field-group">
                        <InputLabel htmlFor="lastName">
                          {configJSON.lastNameTxt}
                        </InputLabel>
                        <Field
                          name="lastName"
                          fullWidth
                          placeholder={configJSON.lastNamePlaceholderTxt}
                          data-testid="lastName"
                          style={webStyle.fieldStyle}
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                        <Grid item xs={12} sm={6} className="field-group">
                          <CustomSelect
                            value={values.gender}
                            data-testid="gender"
                            onChange={(e: any) => setFieldValue("gender", e.target.value)}
                            label="Gender*"
                            renderItem={(item, props) => (
                              <MenuItem
                                key={item.value}
                                value={item.value}
                                {...props}
                              >
                                {item.label}
                              </MenuItem>
                            )}
                            name="gender"
                            id="gender"
                            data={genderItems}
                            placeholder=" Select your Gender"
                          />
                          <ErrorMessage name="gender" component="div" className="error-class"
                        />

                        </Grid>

                        <Grid item xs={12} sm={6} className="field-group3" >
                        <InputLabel htmlFor="birthdate">
                          {configJSON.DOBTxt}
                        </InputLabel>
                        <Field
                          id="birthdate"
                          type="date"
                           fullWidth
                           name="birthdate"
                          //  style={webStyle.InputFieldStyle}
                          placeholder="Select your date of birth"
                          data-testid="dateofBirth"
                          max={new Date().toISOString().split('T')[0]}
                        />
                        <ErrorMessage name="birthdate">
                        {errorMsg => (
                          <div className="error-class" style={{ color: 'red' }}>
                            {errorMsg}
                          </div>
                        )}
                      </ErrorMessage>
                      {values.birthdate && new Date("01/01/1900") >= new Date(values.birthdate) && (
                        <div className="error-class">{configJSON.DateValidationErrorMsg}</div>
                      )}
                      </Grid>

                      <Grid item xs={12} sm={6} className="field-group">
                        <InputLabel htmlFor="profileEmail">
                          {configJSON.emailTxt}
                        </InputLabel>
                        <Field
                          name="profileEmail"
                          fullWidth
                          placeholder={
                            configJSON.forgotPasswordEmailplaceHolder
                          }
                          data-testid="email"
                          disabled={true}
                        />
                        <ErrorMessage
                          name="profileEmail"
                          component="div"
                          className="error-class"
                        />
                      </Grid>

                          <Grid item xs={12} sm={6} className="field-group">
                          <InputLabel htmlFor="phone">{configJSON.mobileNumber} </InputLabel>
                            <PhoneInput
                            test-id="mobileNumber"
                            placeholder="Enter your phone number"
                            inputStyle={{
                              width:"100%",
                              border:"none",
                              borderRadius:"0px",
                              borderBottom:"1px solid #e4e6e8",
                              fontWeight:400,
                              color:'#8083a3',
                            }}
                            value={values.mobileNumber}
                            country={this.state.selectedCountryCode}
                            disableDropdown={true}
                            onChange={(value, data:{dialCode:string,countryCode:string}) => {this.onPhoneChange(value,data)
                                setValues({...values,"dialCode":data.dialCode})
                              }}
                            countryCodeEditable={false}
                          />
                          <ErrorMessage
                            name="mobileNumber"
                            component="div"
                            className="error-class"
                          />
                        </Grid> 

                       <Grid item xs={12} sm={6} className="field-group">
                        <InputLabel htmlFor="address1">
                          {configJSON.addressLine1}
                        </InputLabel>
                        <Field
                          as="textarea"
                          name="address1"
                          placeholder={configJSON.addressline1PlaceholderTxt}
                          style={webStyle.InputFieldStyle}
                          rows="4"
                          data-testid="address1"
                        />
                        <ErrorMessage
                          name="address1"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} className="field-group">
                        <InputLabel htmlFor="address2">
                          {configJSON.addressLine2}
                        </InputLabel>
                        <Field
                          as="textarea"
                          name="address2"
                          placeholder={configJSON.addressline2PlaceholderTxt}
                          style={webStyle.InputFieldStyle}
                          rows="4"
                          data-testid="address2"
                        />
                        <ErrorMessage
                          name="address2"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                        <Grid item xs={12} sm={6} className="field-group">
                          <div className="typography-label">Country*</div>
                          <Autocomplete
                            PaperComponent={CustomPaper}
                            value={values.country}
                            onChange={(event: any, newValue: any) => {
                              this.onCountryChange({ target: { value: newValue } } as any);
                            }}
                            options={this.state.getEnabledCountriesData}
                            renderInput={(params: any) => (
                              <TextField
                                {...params}
                                placeholder="Select your country"
                                name="country"
                                className="custom-autocomplete-textfield"
                              />
                            )}
                            id="country"
                            data-testid="country"
                          />
                          <ErrorMessage name="country" component="div" className="error-class" />
                        </Grid>
                      <Grid item xs={12} sm={6} className="field-group">
                        {!this.state.isLoadingTimezone? 
                         <CustomSelect
                         data-testid="timeZone"
                         value={values.timeZone}
                         onChange={this.onTimeZoneChange}
                         label={configJSON.timeZone} 

                         renderItem={(item, props) => (
                           <MenuItem
                             key={item}
                             value={item}
                             {...props}
                           >
                             {item}
                           </MenuItem>
                         )}
                         labelStyle={{
                           marginBottom:"3px"
                      }}
                      rootStyle={{
                          paddingRight:"20px"
                      }}
                         name="timeZone"
                         id="timeZone"
                         data={this.state.getTimeZonesData}
                         placeholder=" Select your Time Zone"
                       />
                           :<p data-testid="loaderText" style={webStyle.loaderTxtStyle}>{configJSON.loaderTxt}</p>
                           }
                      <ErrorMessage name="timeZone" component="div" className="error-class" />
                      </Grid>
                       <Grid item xs={12} sm={6} className="field-group">
                        {this.renderStateFieldOrSelect(values,errors)}
                        
                      </Grid> 

                      <Grid item xs={12} sm={6} className="field-group">
                        
                        {this.state.cityEnabledData.length === 0 ?
                        <>
                        
                        <InputLabel htmlFor="city">
                          {configJSON.city}
                        </InputLabel>
                         <Field
                         fullWidth
                         displayEmpty
                         placeholder={configJSON.placeholderCity}
                         data-testid="city"
                         name="city"
                         onChange={this.onCityChange}
                         disabled={!values.state}
                         value={this.state.currentUserDetails.city}
                         style={webStyle.InputFieldStyle}
                         />
                         </>
                       :
                        <>
                          <div className="typography-label">City*</div>
                          <Autocomplete
                            value={values.city}
                            data-testid="city"
                            onChange={(event: any, city: any) => {
                              this.onCityChange({ target: { value: city } } as any);
                            }}
                            PaperComponent={CustomPaper}
                            options={this.state.cityEnabledData}
                            renderInput={(params: any) => (
                              <TextField
                              {...params}
                              placeholder="Select your city"
                              name="city"
                              className="custom-autocomplete-textfield"
                              />
                            )}
                            id="city"
                          />
                        </>
                       }
                       <ErrorMessage name="city" component="div" className="error-class" />
                       
                      </Grid>
                      <Grid item xs={12} sm={6}className="field-group2" >
                        <InputLabel htmlFor="postalCode">
                          {configJSON.postalCode}
                        </InputLabel>
                        <Field
                          name="postalCode"
                          fullWidth
                          style={webStyle.fieldStyle}
                          placeholder={configJSON.postalCodePlaceholderTxt}
                          data-testid="postalCode"
                          onKeyPress={this.onKeyPressAvoidNumbers}
                        />
                        <ErrorMessage
                          name="postalCode"
                          component="div"
                          className="error-class"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}className="field-group">
                          <CustomSelect
                            value={values.currency}
                            data-testid="currency"
                            onChange={this.onCurrencyChange}
                            label="Currency*"
                            renderItem={(item, props) => (
                              <MenuItem
                                key={item.id}
                                value={item?.attributes?.currency}
                                {...props}
                              >
                                {item?.attributes?.currency}  ({item?.attributes?.currency_symbol})
                              </MenuItem>
                            )}
                            name="currency"
                            id="currency"
                            data={this.state.currencyData}
                            placeholder=" Select your Currency"
                          />
                          <ErrorMessage name="currency" component="div" className="error-class"
                        />

                        </Grid>

                    </Grid>
                  </Box>
                  <Grid container className="button-block">
                    <Grid item xs="auto">
                      <Button  data-testid="canceldetails"  style={webStyle.cancelButtonStyle} onClick={this.resetFormValues.bind(this, resetForm)}>
                        {configJSON.cancelBtnTxt} 
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button
                        data-testid="submitBtn"
                        type="submit"
                        style={
                          this.props.typeOfRole === "Freelancer"
                            ? webStyle.btnPointerStyle
                            : webStyle.btnPointerSponsorStyle
                        }
                      >
                        {this.state.isLoadingPersonalInfo?<CircularProgress size={20} />:configJSON.saveBtnTxt}
                        
                      </Button>
                      
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>

          
        </Box>

      </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  InputFieldStyle:{
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: '14px',
    color:'#8083a3',
    
  },
  fieldStyle:{ 
    borderBottom: '1px solid rgba(0,0,0,0.1)' ,
    fontWeight:400,
     color:'#8083a3'
    },
  uploadImageStyle:{
    borderRadius:'50%',
    height:'120px',
     width:'120px'
    },
    cancelButtonStyle:{
      minWidth: '140px',
      borderRadius: '23px',
      background: "#f2f2f2",
      padding: '9px 30px',
      color: '#000',
      fontSize: '14px',
      margin: '0 5px',
      textTransform:'none' as const,
      height:'42px',
   },
   fieldMobileStyle:{ 
    borderBottom: '1px solid rgba(0,0,0,0.1)' ,
    fontWeight:700,
     color:'#222',
     marginTop:'14px'
    },
    
    btnPointerStyle:{
      cursor:'pointer',
      minWidth: '140px',
      borderRadius: '23px',
      padding: '9px 30px',
      fontSize: '14px',
      fontWeight:600,
      fontFamily:'Inter',
      margin: '0 5px',
      textTransform:'none' as const,
      height:'42px',
      color:'#f2f2f2',
      backgroundColor: "#ec952d",
    },
    loaderTxtStyle:{
      color:"#8083a3"
    },
    btnPointerSponsorStyle:{
      cursor:'pointer',
      minWidth: '140px',
      borderRadius: '23px',
      padding: '9px 30px',
      color:'#f2f2f2',
      backgroundColor: "#00A7A2",
      fontSize: '14px',
      fontWeight:600,
      fontFamily:'Inter',
      margin: '0 15px',
      height:'42px',
      textTransform:'none' as const,
   
    }
}

// Customizable Area End
