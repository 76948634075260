import React from "react";
import {
  // Customizable Area Start
  Grid,
  Typography,
  Box,
  Avatar,
  WithStyles,
  IconButton,
  Card,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  MoreVert as MoreVertIcon,
  LocationOn as LocationOnIcon,
  QueryBuilder as QueryBuilderIcon,
  Error as ErrorIcon,
  CancelOutlined as CancelOutlined,
  CloseSharp as CloseSharp,
} from "@material-ui/icons";
import {
  leftarrow,
  chat,
  network,
  dots,
  localtime,
  location,
  line,
} from "../../blocks/certificationtracking/src/assets"
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { configJSON } from '../../blocks/certificationtracking/src/PrjDetailsController.web';
// Customizable Area End

export interface Props extends WithStyles {

  // Customizable Area Start
  sponsorData?: any;
  navigation?: any;
  completed?: any;
  deleted?:any;
  disputed?:any;
  onhold?:any;
  lancerData?: any;
  onProjectUpdate?: any;
  onSendMessage?: any
    sendMessage?:any;
    propsInfo?:any;
    resumeProject?:any;
    closureRequestStatus?:string|null;
    resumeDisputeProject?:any;
    resumeLoader?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  localTime: string;
  projectStatus: string;
  open: boolean;
  // Customizable Area End
}

let intervalId: NodeJS.Timeout | number;
export class ProjectHeaderDetails extends React.Component<Props, S> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      localTime: '',
      projectStatus: "",
      open: false,
    };
  }

  componentDidMount() {
    const status = this.props.navigation.getParam("status")
    this.updateLocalTime();
    intervalId = setInterval(this.updateLocalTime, 1000);
    this.setState({ projectStatus: status })

    const isProgressProject=window.location.pathname.split("/")[1] === configJSON.pathPrjSummary;
    const isOnHoldProject=window.location.pathname.split("/")[1] === configJSON.pathOnHoldPrjSummary;
    const isClosedProject=window.location.pathname.split("/")[1] === configJSON.pathClosedPrjSummary;
    const isCompeltedProject=window.location.pathname.split("/")[1] === configJSON.pathCompletedPrjSummary;
    const isDisputedProject=window.location.pathname.split("/")[1] === configJSON.pathDisputePrjSummary;

    const isSponsorDisputedProject = window.location.pathname.split("/")[3] === configJSON.disputeProjectType;
    const isSponsorClosedProject = window.location.pathname.split("/")[3] === configJSON.closedProjectType;
    const isSponsorCompletedProject = window.location.pathname.split("/")[3] === configJSON.completedProjectType;
    const isSponsorOnholdProject = window.location.pathname.split("/")[3] === configJSON.onholdProjectType;

 
    {
      (this.props.sponsorData && 
        this.props.sponsorData?.attributes?.project_status == 7 && isClosedProject)
         && this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.closedType})
    }
    {
      (this.props.sponsorData && 
        this.props.sponsorData?.attributes?.project_status == 7 && isProgressProject) 
        && this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.closedType })
    }
    {
      (this.props.sponsorData && 
        this.props.sponsorData?.attributes?.project_status == 6 && !isOnHoldProject) 
        && this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.onholdType })
    }
    {
      (this.props.sponsorData &&
         this.props.sponsorData?.attributes?.project_status == 0 && !isProgressProject) 
         && this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.progressType })
    }
    {
      (this.props.sponsorData && 
        this.props.sponsorData?.attributes?.project_status == 2 && !isCompeltedProject) 
        && this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.completedType })
    }
    {
      (this.props.sponsorData && 
        this.props.sponsorData?.attributes?.project_status == 5 && !isDisputedProject) 
        && this.props.navigation.navigate(configJSON.pathFreelancerProjects, { type: configJSON.disputeType })
    }
  
    {
      (this.props.lancerData && 
        this.props.lancerData.projectStatus === 5 && !isSponsorDisputedProject) 
        && this.props.navigation.navigate(configJSON.pathProjects, { type: configJSON.disputeProjectType })
    }

    {
      (this.props.lancerData && 
        this.props.lancerData.projectStatus === 7 && !isSponsorClosedProject ) 
        && this.props.navigation.navigate(configJSON.pathProjects, { type: configJSON.closedProjectType })
    }

    {
      (this.props.lancerData && 
        this.props.lancerData.projectStatus === 2 && !isSponsorCompletedProject ) 
        && this.props.navigation.navigate(configJSON.pathProjects, { type: configJSON.completedProjectType })
    }

    {
      (this.props.lancerData && 
        this.props.lancerData.projectStatus === 6 && !isSponsorOnholdProject ) 
        && this.props.navigation.navigate(configJSON.pathProjects, { type: configJSON.onholdProjectType })
    }

  }

  componentWillUnmount() {
    clearInterval(intervalId);
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleResumeProject = () => {
     this.setState({ open: false });
     this.props.resumeDisputeProject()
  }

  updateLocalTime = () => {
    const timeZone = this.props.sponsorData ? this.props.sponsorData.attributes?.sponsor_profile?.time_zone : this.props.lancerData?.timeZone

    const localCityTime = this.getLocalTime(timeZone);
    const formattedTime = this.formatTime(localCityTime);
    this.setState({ localTime: formattedTime });
  };

  getLocalTime = (timeZone: any) => {
    const now = new Date();
    const options: any = {
      timeZone,
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };
    try{
      return now.toLocaleTimeString('en-US', options)
    }catch(err){
      delete options.timeZone;
      return now.toLocaleTimeString('en-US', options)
    }
   
  };

  formatTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(':');
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
  };
  handleSendMessage=()=>{
    const sponsorId=this.props.sponsorData?.attributes?.sponsor_profile?.account_id || "";
    // const freelancerId=this.props.sponsorData?.attributes?.Hired_freelancers?.account_id;
    const projectId=this.props.sponsorData?.attributes?.id;
    const createChatMessage: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    createChatMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Chat"
    );
    createChatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props.propsInfo);
     
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      createConversation:true,
      userId:sponsorId,
      projectId:projectId,
      userType:"sponsor"
    });
    createChatMessage.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.props.sendMessage(createChatMessage);

  }
  
  render() {
    const { classes, sponsorData, navigation, completed,deleted,disputed, lancerData,onhold , resumeProject } = this.props;
    let closureRequestStatus:any = this.props.closureRequestStatus
    closureRequestStatus ? closureRequestStatus = true : closureRequestStatus = false
    const { open } = this.state;
    return (
      <>
        {sponsorData && <Box className={classes.headerContentBox}>
          <Grid container>
            {sponsorData &&
              <Grid item xs={12} className={classes.projectDetailsGrid}>
                <Box className={classes.projectDetailsBox}>
                  <img onClick={() => { navigation?.navigate("FreelancerProjects",{  type: deleted ? "closed" : disputed ? "dispute" : completed ? 'completed' : onhold ? "onhold" : 'progress'}) }} 
                  className={classes.leftArrowImg} src={leftarrow} />
                  <Typography className={classes.projectDetailsName}>{sponsorData.attributes?.project_name}</Typography>
                </Box>
                <Grid container className={classes.projectDetailsAvatarBox} spacing={1}>
                  <Grid item xs={12} sm="auto">
                  <Avatar src={sponsorData.attributes?.profile_image == null ? "" : sponsorData.attributes?.profile_image[0]?.url} className={classes.projectDetailsAvatar} />

                  </Grid>
                  <Grid item xs={12}  sm="auto">

                  <Box className={classes.projectDetailsNameBox}>
                    <Typography className={classes.projectDetailsLancerName}>{sponsorData.attributes?.profile_full_name}</Typography>
                    <Box mt={1} className={classes.projectDetailsNameSubBox}>
                      <img className={classes.projectDetailsNameSubBoxImg} src={location} />
                      <Typography className={classes.projectDetailsNameSubBoxText}>{sponsorData.attributes?.sponsor_profile.city}, {sponsorData.attributes?.sponsor_profile.country}
                      </Typography>
                      <Typography className={classes.projectDetailsNameSubBoxText}> . </Typography>
                      <img className={classes.projectDetailsNameSubBoxImg} src={localtime} />
                      <Typography className={classes.projectDetailsNameSubBoxText}>{this.state.localTime}</Typography>
                    </Box>
                  </Box>
                  </Grid>
                  <Grid item xs={12} sm>
                  <Box>
                    <img className={classes.lineImg} src={line} />
                    <img style={{ cursor: "pointer" }} className={classes.chatImg} src={chat} onClick={this.handleSendMessage} />
                    {/* <img className={classes.chatImg} src={network} />
                    <img className={classes.chatImg} src={dots} /> */}
                  </Box>
                    
                  </Grid>
                </Grid>
              </Grid>}

          </Grid>
        </Box>}
        {lancerData &&
          <Box className="resLancerContainer" style={webStyle.topContainer}>
            <Box className={classes.titleBlockStyle}>
              <KeyboardBackspaceIcon className={classes.backspaceIcon}
                data-testid="navIcon"
                onClick={() => this.props.navigation.navigate('Projects', { type: this.state.projectStatus })} />
              <Typography className={classes.titleStyle}>
                {lancerData.projectTitle} 
              </Typography>
            </Box>
            <Card className={classes.cardStyle} elevation={0}>
                <Grid container className={classes.projectDetailsAvatarBox} spacing={1}>
                <Grid item xs={12} sm="auto">
                <Avatar src={lancerData.lancerPhoto?lancerData.lancerPhoto:""} />
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Box className={classes.projectDetailsNameBox}>
                  <Typography className={classes.userNameStyle}>{lancerData.userName}</Typography>
                    <Box mt={1} className={classes.subDetailsBlock}>
                      <Box display="flex" alignItems="center">
                        <LocationOnIcon className={classes.iconStyle} />
                        <span className={classes.subTitleStyle}>{lancerData.location}</span>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <QueryBuilderIcon className={classes.iconStyle} />
                        <span className={classes.subTitleStyle}>{this.state.localTime}</span>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item xs={12} sm className="projectIcon">
                    
                    <IconButton onClick={this.props.onSendMessage}>
                      <img src={chat} alt="message icon" />
                    </IconButton>

                </Grid>
              </Grid>
            </Card>
            <style>
              {`
                .projectIcon {
                  display: flex;
                  align-items:center;
                  gap: 10px;
                }
                @media(max-width: 480px) {
                  .resLancerContainer {
                      padding: 10px !important;
                  }
                }
                
              `}
            </style>
            {(this.state.projectStatus !== "closedproject")&&
            (this.state.projectStatus !== "completedproject") && 
            (this.state.projectStatus !== "disputeproject")  &&
            (this.state.projectStatus !== "projectonhold") && (
              <>
                <Divider className={classes.dividerStyle} />
                <Box className={classes.btnContainerStyle}>
                  <Button
                    className={classes.closeBtnSyle}
                    onClick={() => this.props.onProjectUpdate('closed')}
                    data-testid="closebutton"
                    >
                    Close Project 
                  </Button>
                  <Button
                    onClick={() => this.props.onProjectUpdate('on-hold')}
                    className={classes.onHoldBtnStyle}>
                    Put on Hold 
                  </Button>
                </Box>
              </>
            )}

          {(this.state.projectStatus == "disputeproject" || this.state.projectStatus == "projectonhold" || this.state.projectStatus === "closedproject" ) &&
            <>
                <Divider className={classes.dividerStyle} />
                <Box className={classes.btnContainerStyle}>
                  <Button
                    onClick={this.handleOpen}
                    className={classes.closeBtnSyle}
                    disabled={this.props.resumeLoader}
                    >
                   {this.props.resumeLoader?<CircularProgress size={24} />: "Resume Project"}   
                 
                  </Button>
                </Box>

          <Dialog 
          PaperProps={{
            style: {
              borderRadius: '14px',
              boxShadow: 'none',
            },
          }}
            open={open} 
            onClose={this.handleClose}>
          <DialogTitle style={webStyle.modalResumeTitle}>
            <Typography style={webStyle.modalResumeTitleTxt}>Resume Project</Typography>
            </DialogTitle>
          <DialogContent>
            <DialogContent style={webStyle.modalResumeContent}>
            <Typography style={webStyle.modalResumeText}> Are you sure you want to resume the project?</Typography>
             
            </DialogContent>
          </DialogContent>
          <DialogActions style={webStyle.modalResumeBtnBox}>
            <Button 
              onClick={this.handleClose} 
              style={webStyle.cancelResumeBtn}
              >
              {configJSON.cancelButton}
            </Button>
            <Button 
            className={classes.submitResumeButon}
            onClick={this.handleResumeProject}
            style={webStyle.submitResumeBtn}
             autoFocus>
              Resume
            </Button>
          </DialogActions>
          </Dialog>
          </>
          }

          </Box>}
      </>)

  }
}

// Customizable Area Start

export const styles = () => ({
  titleStyle: {
    '@media (max-width: 300px)': {
      fontSize: "12px !important"
    }
  },
  flexItem: {
    display: "flex",
    alignItems: "center",
  },
  mainContainer: {
    margin: '2vw auto',
    width: "95%"
  },
  mainGridContainer: {
    display: 'flex',
  },

  menuGrid: {
    display: 'flex',
    flexDirection: 'column' as const,
  },
  menuBox: {
    backgroundColor: '#f5f5fab3',
    borderRadius: 14,
    border: '1px solid rgba(228,230,232,0.6)',
    margin: '1vw',
  },

  headerContentGrid: {
    display: 'flex',
    flexDirection: 'column' as const,
    minHeight: '50vh'
  },
  headerContentBox: {
    borderRadius: '14px',
    border: '1px solid #e4e6e8',
    margin: '0 1vw ivw 1vw',
  },
  headerText: {
    color: '#222222',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: 700,
    margin: '2vw 1vw',
  },
  projectDetailsGrid: {
    padding: '1.5vw',
    '@media (max-width: 600px)': {
      padding:"15px",
    }
  },
  projectDetailsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  projectDetailsAvatarBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1vw'
  },
  leftArrowImg: {
    paddingRight: '1vw',
    height: '13px',
    width: '20px',
    cursor: 'pointer'
  },
  projectDetailsName: {
    color: '#222222',
    fontSize: '20px',
    fontWeight: 700,
    '@media (max-width: 500px)': {
      paddingLeft:"15px",
    }
  },
  projectDetailsAvatar: {
    width: '50px',
    height: '50px',
  },
  projectDetailsLancerName: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 600,
  },
  lineImg: {
    paddingRight: '1vw',
    height: '30px',
    width: '5px',
  },
  chatImg: {
    paddingRight: '1vw',
    height: '24px',
    width: '25px',
  },
  projectDetailsNameBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    paddingRight: '2vw',
    paddingLeft: '1vw',
  },
  projectDetailsNameSubBox: {
    display: 'flex',
    alignItems: 'center',
  },
  projectDetailsNameSubBoxImg: {
    paddingRight: '0.3vw',
    height: '14px',
    width: '14px',
  },
  projectDetailsNameSubBoxText: {
    paddingRight: '0.3vw',
    color: '#8083a3',
    fontSize: '12px',
    fontWeight: 500,
  },
  
  buttonBox: {
    display: 'flex',
    paddingRight: '2vw',
    margin: '2vw 1vw',
  },
  buttonTab: {
    color: '#8083a3',
    fontSize: '12px',
    fontWeight: 700,
    width: '168px',
    height: '42px',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonTabActive: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700,
    width: '168px',
    height: '42px',
    borderRadius: '20px',
    backgroundColor: '#ec952d',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hideImgBox: {
    visibility: 'hidden' as const,
  },
});

const webStyle = {
cancelResumeBtn:{
  borderRadius: '30px',
  textTransform: "none" as const,
  color: '#222',
  backgroundColor:'#f2f2f2',
  display:'flex', 
  alignItems:'center',
  justifyContent:'center',
  boxShadow:'none',
  fontSize: '12px',
  fontWeight: 700,
  margin:'0 5px',
  height:'30px',
  width:'100px', 
},
submitResumeBtn:{
  color: '#fff',
  fontSize:'12px',
  fontWeight: 700,
  width: '82px',
  height: '30px',
  borderRadius: '30px',
  backgroundColor: "rgb(0, 167, 162)" ,
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  textTransform:'none' as const,
  margin:'0 5px',
},
modalResumeTitle:{ 
  padding: '16px 24px 0 24px',
},
modalResumeTitleTxt:{ 
  fontWeight: 600,
  color:'#222',
  fontFamily:'inter',
  fontSize:'20px',
},
modalResumeContent:{ 
 padding: '8px 24px 8px 0'
},
modalResumeText:{
  paddingBottom: "20px",
  fontWeight: 400,
  color:'#222',
  fontFamily:'inter',
  fontSize:'14px'
},

modalResumeBtnBox: {
  padding: '8px 19px 20px 24px',
},

topContainer: {
  borderRadius: 14,
  border: "1px solid #E4E6E8",
  padding: 25,
}
}

export default withStyles(styles)(ProjectHeaderDetails);
// Customizable Area End