import React,{useState} from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { basicInfoSchema } from "../../../blocks/social-media-account-registration/src/validationSchema";
import InputError from "../../../blocks/social-media-account-registration/src/InputError.web";
import "../../../blocks/social-media-account-registration//assets/css/Accountinfo.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { createTheme,ThemeProvider } from "@material-ui/core/styles";
import { Select,MenuItem,FormControl,FormHelperText,Grid, Box, TextField } from "@material-ui/core";
import FormSaver from './FormSaver.web';
import CountryTextInput from "../utill/CountryTextInput.web";
import StateTextInput from "../utill/StateTextInput.web";
import CustomSelect from "../utill/CustomSelect.web";
import { ICurrencyInfo } from "../../../blocks/social-media-account-registration/src/AccountinfoController.web";
import { toast } from "react-toastify";
import { Autocomplete } from '@material-ui/lab'
import '../../src/utill/customAutocomplete.css'
import { CustomPaper } from "../utill/CountryTextInput.web";
interface Props{
    onSubmit:(values:any)=>void;
    initialValues:any;
    saveUserInfo:boolean;
    file:any;
    handleDrop:any;
    handleFileChange:any;
    timeZoneList:string[];
    cityLoading:boolean;
    cityList:string[];
    countryList:string[];
    stateList:string[];
    stateLoading:boolean;
    countryLoading:boolean;
    onCountrySelected:any;
    getCityList:any;
    onCancel:any;
    countryCode:string;
    currencyList:ICurrencyInfo[];
}
const theme = createTheme({
  palette: {

  },
  overrides: {
      MuiFormLabel: {
        root: {
          lineHeight: "none",
          "&.Mui-focused":{
            color:"#8083a3",
            fontSize:"14px",
            fontWeight:400
        }
        }
      },
      MuiInputBase: {
        input: {
          padding: 0,
          '&::placeholder': {
            color: "#8083a3", 
            fontSize:"14px",
            fontWeight:700,
          },  
        }
      },
      
      MuiButton:
          {
            contained :{
            color: '#fff'
          }
        },
    }
});
const BasicDetailsForm:React.FC<Props> = ({
    onSubmit,
    initialValues,
    saveUserInfo,
    file,
    handleDrop,
    handleFileChange,
    timeZoneList,
    cityList,
    cityLoading,
    countryList,
    countryLoading,
    stateList,
    stateLoading,
    onCountrySelected,
    getCityList,
    onCancel,
    countryCode="us",
    currencyList
}) => {
    const inputRef = React.useRef<any>(null);
    const basicDetailsFormRef=React.useRef<any>(null);
    const [profileimg, setProfileimg] = useState<any>(null)
    const [savePhoto, setSavePhoto] = useState(false)
  
    React.useEffect(()=>{
      const savedData = JSON.parse(localStorage.getItem("Basicdetailarray") || "{}");
      if (savedData && savedData.profilephoto) {
        const profilePhoto = savedData.profilephoto;
        
        if (typeof profilePhoto === 'string') {
          try {
            const file = base64ToFile(profilePhoto, "profilephoto.jpg");
            setProfileimg(file);
          } catch (error) {
            console.error('Error parsing base64 string:', error);
          }
        } else {
          console.error('Saved profile photo is not a valid string');
        }
      }

       const isCountrySelected=Boolean(basicDetailsFormRef?.current?.values?.country);
        if(timeZoneList?.length>0){
           basicDetailsFormRef.current.setFieldValue("time_zone",timeZoneList[0])
        }else if(isCountrySelected && timeZoneList?.length===0){
          basicDetailsFormRef.current.setFieldValue("time_zone","Asia/Kolkata")
        }
    },[timeZoneList])

    const base64ToFile = (base64String: string, fileName: string): File | null => {
      // Validate that the base64String is a valid string
      if (typeof base64String !== 'string') {
        console.error('Invalid Base64 string:', base64String);
        return null;  // Return null if invalid
      }
      // Split the Base64 string to extract metadata and data
      const parts = base64String.split(",");
      // Ensure that there are exactly two parts (metadata and data)
      if (parts.length !== 2) {
        console.error('Invalid Base64 format. Could not split into metadata and data:', base64String);
        return null;
      }
      const [metadata, data] = parts;
      const mimeTypeMatch = metadata.match(/:(.*?);/);

      if (!mimeTypeMatch) {
        console.error('Could not extract MIME type from Base64 string:', base64String);
        return null;
      }
      const mimeType = mimeTypeMatch[1]; // Extract MIME type
      try {
        // Convert Base64 string to a binary buffer using Buffer
        const byteString = Buffer.from(data, 'base64');
        // Create a File object from the binary data
        return new File([byteString], fileName, { type: mimeType });
      } catch (error) {
        console.error('Error while converting Base64 to File:', error);
        return null; // In case of any errors, return null
      }
    };

    const handleDragOver = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
  
    const handleDragEnter = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
  
    const handleDragLeave = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const handleClick = (event: any) => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };
   const getTodayDate=()=>{
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
    
        return `${yyyy}-${mm}-${dd}`;
      }

      const handleFileChange2 = (event: any, setFieldValue: any) => {
        const file = event.target.files?.[0];
        if (file && file instanceof File) {
          const validationResult = validateFile(file);
          if (validationResult.error) {
            toast.error(validationResult.error,{position: "top-right"})
          } else {
            handleFileChange(event)
            setSavePhoto(true)
            setFieldValue("profilephoto", file);
            setProfileimg(file);
          }
        }
      };

      const validateFile = (file: File) => {
        const validTypes = ["image/jpeg", "image/png", "image/jpg"];
        const maxSize = 5 * 1024 * 1024;
      
        if (!validTypes.includes(file.type)) {
          return { error: "Only jpeg, jpg, and png files are allowed." };
        }
      
        if (file.size > maxSize) {
          return { error: "File size is too large. Max size is 5MB." };
        }
      
        return { error: null };
      };
      

    return ( 
      <Formik
      innerRef={basicDetailsFormRef}
      enableReinitialize={true}
      validationSchema={basicInfoSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
       {(props)=>(
         <FormSaver data-testid="fileSaver" {...props} saveFieldName={"Basicdetailarray"} saveInfo={saveUserInfo} savePhoto={savePhoto}>
          {({ isSubmitting, values, setFieldValue, errors ,setValues}:any) => (
            <Form translate={undefined}>
              <div className="upload-block">
                <div
                  id="dropzone"
                  className="drop-block"
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onClick={handleClick}
                >
                    {!profileimg ? <p>
                      {values.profilephoto && values.profilephoto instanceof File ?
                        (<img
                          style={{
                            height: "100%",
                            objectFit: "cover",
                            width: "100%",
                            borderRadius: "50%",
                          }}
                          src={URL.createObjectURL(values.profilephoto)}
                        />
                        ) : (
                          <>
                            Attach or Drop photo here
                            <br />
                            <small>(5 MB Max)</small>
                          </>
                        )}
                    </p>
                      :
                      <p style={{ height: "100%", width: "100%" }}>
                        {profileimg && profileimg instanceof File &&
                          <img
                            style={{
                              height: "100%",
                              objectFit: "cover",
                              width: "100%",
                              borderRadius: "50%",
                            }}
                            src={URL.createObjectURL(profileimg)}
                          />
                        }
                      </p>
                    }
                </div>
                <h6 style={{fontWeight:800}}>Show the best version of yourself to the Sponsors</h6>
                <p>Must be an actual photo of you.</p>
                <div className="upload-btn-wrapper">
                  <input
                    type="file"
                    accept="image/*"
                    name="profilephoto"
                    onChange={(e)=>handleFileChange2(e, setFieldValue)}
                    style={{ display: "none" }}
                    ref={inputRef}
                  />
                </div>
              </div>
              <Box sx={{paddingX:{xs:2,sm:5}}}>
                <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="first_name">First Name*</label>
                        <Field
                          test-id="firstName"
                          tabIndex={1}
                          type="text"
                          id="firstName"
                          name="first_name"
                          placeholder="Enter your first name"
                        />
                        <InputError errors={errors} name="first_name" />
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name*</label>
                        <Field
                          test-id="last_name"
                          tabIndex={2}
                          type="text"
                          id="last_name"
                          name="last_name"
                          placeholder="Enter your last name"
                        />
                        <InputError errors={errors} name="last_name" />
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}> 
                      <CustomSelect
                          value={values.gender}
                          onChange={(e:any)=>setFieldValue("gender",e.target.value)}
                          label="Gender*"
                          renderItem={(item,props)=>(
                              <MenuItem
                                key={item}
                                value={item}
                                {...props}
                              >
                              {item}
                            </MenuItem>
                          )}
                          name="gender"
                          id="gender"
                          tabIndex={3}
                          data={["Female","Male","Other","Prefer not to share"]}
                          error={errors["gender"]}
                          placeholder="Select your gender"
                          labelStyle={{
                            marginBottom:values?.gender?"12px":"10px",
                            marginTop:"0px"
                          }}
                          selectStyle={{
                            paddingBottom:"2px"
                          }}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="date_of_birth">
                          {/* <DateRangeRoundedIcon /> */}
                          Date of Birth*
                        </label>
                        <Field
                          test-id="dateof"
                          tabIndex={4}
                          placeholder="Select your date of birth"
                          className="textbox-n"
                          type="date"
                          name="date_of_birth"
                          max={getTodayDate()}
                          selected={values.date_of_birth}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("date_of_birth", e.target.value);
                          }}
                        />
                        <InputError errors={errors} name="date_of_birth" />
                        {
                          values?.date_of_birth && new Date("01/01/1900") >= new Date(values?.date_of_birth) && <InputError errors={{ date_of_birth: "DOB should not be less than 1900" }} name="date_of_birth" />
                        }
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CountryTextInput
                          tabIndex={5}
                          countryList={countryList}
                          countryLoading={countryLoading}
                          onSelected={onCountrySelected}
                          setFieldValue={setFieldValue}
                          value={values?.country}
                          error={errors["country"]}
                          currencyList={currencyList}
                        />
                        <InputError errors={errors} name="country" />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group signin-phone">
                        <label htmlFor="address">Mobile Number*</label>
                        <PhoneInput
                          inputProps={{
                            tabIndex:6
                          }}
                          test-id="phone_number"
                          inputStyle={{
                            width:"100%",
                            border:"none",
                            borderRadius:"0px",
                            borderBottom:"1px solid #e4e6e8"
                          }}
                          value={values.mobile_number}
                          country={countryCode || "in"}
                          onChange={(value, data:{dialCode:string,countryCode:string}, event, formattedValue) => { console.log(data,"kartik")
                            setValues({...values,"mobile_number":value,"countryCode":data.countryCode.toUpperCase(),"dialCode":data.dialCode})
                          }}
                          disableDropdown={true}
                          placeholder="Enter your phone number"
                          countryCodeEditable={false}
                        />
                        <InputError errors={errors} name="mobile_number" />
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="address">Address Line 1*</label>
                        <Field
                          test-id="address"
                          as="textarea"
                          id="address"
                          name="address"
                          rows="4"
                          placeholder="Enter your address line 1"
                          tabIndex={7}
                        />
                        {errors?.address && (
                          <p className="error">{errors?.address}</p>
                        )}
                      </div> 
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="address_line_2">
                          Address Line 2*
                        </label>
                        <Field
                          test-id="address_line_2"
                          as="textarea"
                          id="address_line_2"
                          name="address_line_2"
                          rows="4"
                          placeholder="Enter your address line 2"
                          tabIndex={8}
                        />
                        <InputError errors={errors} name="address_line_2" />
                      </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CustomSelect
                          value={values?.country?.trim() && timeZoneList?.length===0?"No time zone for this country":values.time_zone}
                          onChange={(e:any)=>setFieldValue("time_zone",e.target.value)}
                          label="Time Zone*"
                          renderItem={(item,props)=>(
                              <MenuItem
                                key={item}
                                value={item}
                                {...props}
                              >
                              {item}
                            </MenuItem>
                          )}
                          
                          name="time_zone"
                          id="time_zone"
                          tabIndex={9}
                          data={values?.country?.trim() && timeZoneList?.length>0 ?timeZoneList:["No time zone for this country"]}
                          error={errors["time_zone"]}
                          placeholder="Time Zone"
                          disabled={!values?.country?.trim()}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <StateTextInput
                          tabIndex={10}
                          stateList={stateList}
                          stateLoading={stateLoading}
                          setFieldValue={setFieldValue}
                          fetchCities={getCityList}
                          country={values?.country}
                          error={values?.country?.trim() && stateList?.length>0 && errors?.state?errors.state:errors?.state ? "Please enter your state":""}
                          value={values.state}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {
                        !cityLoading && cityList?.length===0 && values?.country && values?.state ? (
                        <div className="form-group">
                          <label htmlFor="city">City*</label>
                          <Field
                            test-id="city"
                            id="city"
                            name="city"
                            placeholder="City"
                            tabIndex={11}
                            
                          />
                            <InputError errors={errors?.city?{city:"Please enter city"}: {}} name="city" />
                        </div>
                        ):(
                          <>
                              <div className="typography-label">City*</div>
                              <Autocomplete
                                value={values.city}
                                data-testid="city"
                                PaperComponent={CustomPaper}
                                onChange={(e: any, newValue: any) => setFieldValue("city", newValue)}
                                options={cityList}
                                getOptionLabel={(option: any) => option}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select your city"
                                    name="city"
                                    className="custom-autocomplete-textfield"
                                  />
                                )}
                                id="city"
                                disabled={!values?.state?.trim()}
                              /> 
                              <InputError errors={errors} name="city" />
                          </>
                        )
                      } 
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CustomSelect
                          value={values?.currency}
                          onChange={(e:any)=>setFieldValue("currency",e.target.value)}
                          label="Currency*"
                          renderItem={(item:ICurrencyInfo,props)=>(
                              <MenuItem
                                key={item?.id}
                                value={item?.id}
                                {...props}
                              >
                              {item?.attributes?.currency}({item?.attributes?.currency_symbol})
                            </MenuItem>
                          )}
                          
                          name="currency"
                          id="currency"
                          tabIndex={12}
                          data={currencyList}
                          error={errors["currency"]}
                          placeholder="Currency"
                          disabled={!values?.country?.trim()}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="postal_code">Postal Code*</label>
                          <Field
                            test-id="postal_code"
                            type="number"
                            id="postal_code"
                            name="postal_code"
                            pattern="[0-9]*"
                            placeholder="Enter postal code"
                            tabIndex={13}
                          />
                        <InputError errors={errors} name="postal_code" />
                      </div>
                    </Grid>
                </Grid>
              </Box>
              {/* <div className="row">
                
              </div> */}
              <Grid container className="button-block">
                <Grid item xs="auto">
                <button
                  test-id="cancel-btn"
                  type="button"
                  className="button btn"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>

                </Grid>
                <Grid item xs="auto">
                <button
                  test-id="submitButton"
                  type="submit"
                  className="button btn-orange"
                >
                  Continue
                </button>
                </Grid>
              </Grid>
            </Form>
          )}
      </FormSaver>
       )}
    </Formik>
     );
}
 
export default BasicDetailsForm;